import { Component, Input, OnInit } from '@angular/core';
import { EntityProgressStatus } from '@app/model/enums/entity-progress-status.enum';
import { CardColorSchemeType } from 'library-explorer';

@Component({
  selector: 'app-entity-progress-spinner',
  templateUrl: './entity-progress-spinner.component.html',
  styleUrls: ['./entity-progress-spinner.component.scss']
})
export class EntityProgressSpinnerComponent implements OnInit {
  @Input() public progress: number;
  @Input() public diameter = 40;
  @Input() public status: EntityProgressStatus = null;
  @Input() public colorScheme: CardColorSchemeType;
  
  public readonly statuses: typeof EntityProgressStatus = EntityProgressStatus;
  public readonly colorSchemes: typeof CardColorSchemeType = CardColorSchemeType;

  constructor() { }

  ngOnInit(): void {
  }

}
