<ng2-pdfjs-viewer
  *ngIf="src"
  [pdfSrc]="src"
  [download]="allowDownloadFile && !forceFullScreen"
  [fullScreen]="true"
  [viewBookmark]="false"
  [showSpinner]="false"
  [openFile]="false"
  [print]="allowDownloadFile"
  [find]="!forceFullScreen"
  [print]="!forceFullScreen"
  zoom="page-width"
  [viewerId]="viewerId"
  (onDocumentLoad)="pdfLoaded()"
  (onPageChange)="onPageChange()">
</ng2-pdfjs-viewer>
