import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { EntityItemLayoutEnum } from 'library-explorer';

@Component({
  selector: 'app-list-layout-switcher',
  templateUrl: './list-layout-switcher.component.html',
  styleUrls: ['./list-layout-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListLayoutSwitcherComponent {
  @Input() public layout: EntityItemLayoutEnum = EntityItemLayoutEnum.ROW;
  @Output() public layoutChanged: EventEmitter<EntityItemLayoutEnum> = new EventEmitter<EntityItemLayoutEnum>();

  @Input() public layouts: typeof EntityItemLayoutEnum = EntityItemLayoutEnum;

  public changeLayoutView(layout: EntityItemLayoutEnum): void {
    this.layout = layout;
    this.layoutChanged.emit(layout);
  }
}
