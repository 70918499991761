import { Directive, ElementRef, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { BundleType, EntityTypeId, FieldName, ImageModel } from '../models';
import { FileUploadApiService, TokenApiService } from '../services';
import { CloudImageService } from '../services/cloud-image.service';
import { SharedLibraryService } from '../services/shared-library.service';
import { CropImageDirective } from './crop-image.directive';

@Directive({
  selector: '[blockBackground]'
})
export class BlockBackgroundDirective extends CropImageDirective {
  @Input() public bundle!: BundleType;

  @Input() public set blockBackground(value: { backgroundColor: string, mediaBackground: ImageModel }) {
    if (!value) {
      return;
    }

    this.cropImage = '';

    if (value.backgroundColor || value.mediaBackground) {
      if (value.mediaBackground) {
        this.additionalOptions = {
          func: 'bound'
        };

        const isPhone = window.matchMedia('(max-width: 768px)').matches;
        this.dynamicWidthCoeff = isPhone ? 3 : 1;
        this.el.nativeElement.style.backgroundSize = 'cover';

        this.loadPresignUrl(value.mediaBackground)
          .subscribe(url => {
            this.cropImage = url;
          });

      } else if (value.backgroundColor) {
        this.el.nativeElement.style.backgroundColor = value.backgroundColor;
      }
    } else {
      this.el.nativeElement.style.backgroundColor = null;
      this.el.nativeElement.style.backgroundImage = null;
    }
  }

  constructor(
    protected override readonly sharedLibraryService: SharedLibraryService,
    protected override el: ElementRef,
    protected override tokenApiService: TokenApiService,
    private readonly fileUploadApiService: FileUploadApiService,
    protected override cloudImageService: CloudImageService) {
    super(sharedLibraryService, el, tokenApiService, cloudImageService);
  }

  private loadPresignUrl(image: ImageModel): Observable<string> {
    return this.fileUploadApiService.getPreSignedUrl(EntityTypeId.PARAGRAPH, this.bundle, FieldName.MEDIA_BACKGROUND, image.key || '');
  }
}
