import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BundleType } from 'library-explorer';
import { EntityItemSizeEnum } from '@app/model/enums/entity-item-size.enum';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { CarouselType } from 'src/app/model/enums/carousel-type.enum';
import { NodeModel } from 'library-explorer';
import { PayableNodeNavigationService } from 'src/app/services/payable-node-navigation.service';
import { PaymentService } from 'src/app/services/payment.service';
import { LanguageService } from '@app/services/language.service';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
  @ViewChild(SlickCarouselComponent, { static: false }) slick: SlickCarouselComponent;

  @Input() cardSize: EntityItemSizeEnum;
  @Input() type: CarouselType = CarouselType.CLASS;
  @Input() public config = {
    slidesToScroll: 1,
    lazyLoad: 'ondemand',
    infinite: true,
    arrows: true,
    autoplay: false,
    centerMode: false,
    slidesToShow: 4,
    rtl: false,
    responsive: [
      {
        breakpoint: 1661,
        settings: {
          slidesToShow: 3,
          arrows: false,
          dots: true
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          arrows: false,
          dots: true
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          arrows: false
        }
      }
    ]
  };
  @Input('items') public set items(data: any[]) {
    if (this.slick) {
      this.slick.slickGoTo(0);
    }
    this._items = data || [];
  }

  public get items(): any[] {
    return this._items;
  }

  public showCarouselBtns = false;
  public mousePosition: any = {};
  public CarouselType = CarouselType;
  public isPaymentEnabled = false;
  public slideIndex = 0;
  private _items: any[];

  constructor(
    private readonly payableNodeNavigationService: PayableNodeNavigationService,
    private readonly paymentService: PaymentService,
    private readonly router: Router,
    private readonly languageService: LanguageService
  ) { }

  ngOnInit(): void {
    this.paymentService.getPaymentConfig().subscribe(res => {
      this.isPaymentEnabled = !!res.enabled;
    });

    const isRtl = this.languageService.isRtl();
    if (isRtl) {
      this.config.rtl = true;
    }
  }

  public onMouseDown(event) {
    this.mousePosition.x = event.screenX;
    this.mousePosition.y = event.screenY;
  }

  public slickInit(): void {
    this.afterChange();
  }

  public afterChange(event?): void {
    if (event) {
      this.slideIndex = event.currentSlide;
    }
    
    setTimeout(() => {
      const slidesToShow = window.matchMedia('(min-width: 1440px)') ? 3 : 2;
      const slideCount = this.items.length;

      this.showCarouselBtns = slidesToShow < slideCount;
    });
  }

  public navigate(item: NodeModel, event: any): void {
    switch (item.bundle) {
      case BundleType.WEBINAR:
      this.navigateToWebinar(item, event);
        break;
      default:
        this.payableNodeNavigationService.navigate(item, event, this.mousePosition);
    }
  }

  private navigateToWebinar(item: NodeModel, event: any): void {
    const { x, y } = this.mousePosition;

    if (Math.abs(x - event.screenX) > 10 || Math.abs(y - event.screenY) > 10) {
      return;
    }

    this.router.navigate(['/webinars', item.uuid]);
  }
}
