import { Directive, ElementRef, Input } from '@angular/core';

import { BackgroundConfiguration, BackgroundSizeOption, HorizontalPositionOption, VerticalPositionOption } from '../models';
import { TokenApiService } from '../services';
import { CloudImageService } from '../services/cloud-image.service';
import { SharedLibraryService } from '../services/shared-library.service';
import { CropImageDirective } from './crop-image.directive';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[mediaBackground]'
})
export class MediaBackgroundDirective extends CropImageDirective {
  @Input() public set mediaBackground(value: BackgroundConfiguration) {
    this._mediaBackground = value;
    this.initializeElementBackground();
  }

  public get mediaBackground() {
    return this._mediaBackground;
  }

  private mediaQuery = window.matchMedia('(min-width: 768px)');

  private _mediaBackground!: BackgroundConfiguration;

  constructor(
    protected override readonly sharedLibraryService: SharedLibraryService,
    protected override readonly el: ElementRef,
    protected override readonly tokenApiService: TokenApiService,
    protected override cloudImageService: CloudImageService) {
    super(sharedLibraryService, el, tokenApiService, cloudImageService);
    this.mediaQuery.addEventListener('change', () => this.initializeElementBackground());
  }

  protected initializeElementBackground(): void {
    if (!this._mediaBackground) {
      this.el.nativeElement.style.backgroundColor = null;
      this.el.nativeElement.style.backgroundImage = null;
      this.cropImage = '';
      return;
    }

    this.el.nativeElement.style.backgroundColor = this._mediaBackground.backgroundColor;

    this.setBackgroundImage();
  }

  protected setBackgroundImage(): void {
    const isDesktop = this.mediaQuery.matches;
    const { mediaBackground, mobileMediaBackground } = this._mediaBackground;

    const backgroundMedia = isDesktop ? mediaBackground : mobileMediaBackground || mediaBackground;
    const isEmptyArray = Array.isArray(backgroundMedia) && !backgroundMedia?.length;
    
    if (!backgroundMedia || isEmptyArray) {
      this.el.nativeElement.style.backgroundImage = null;
      this.cropImage = '';

      return;
    }

    const [backgroundSize, backgroundPosition] = [
      isDesktop ? this._mediaBackground.backgroundSize : this._mediaBackground.mobileBackgroundSize, 
      isDesktop ? this._mediaBackground.backgroundPosition : this._mediaBackground.mobileBackgroundPosition, 
    ];

    this.additionalOptions = {
      width: this.dynamicWidth ? this.el.nativeElement.offsetWidth * this.dynamicWidthCoeff : this.imageWidth,
      func: 'bound'
    };

    this.el.nativeElement.style.backgroundSize = backgroundSize || BackgroundSizeOption.COVER;
    const { horizontal = HorizontalPositionOption.LEFT, vertical = VerticalPositionOption.TOP } = backgroundPosition || {};
    this.el.nativeElement.style.backgroundPosition = `${vertical} ${horizontal}`;
    this.el.nativeElement.style.backgroundRepeat = 'no-repeat';

    this.cropImage = backgroundMedia.uri || backgroundMedia.url || '';
  }
}
