import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';

let SecureStorage: any;
@Injectable({
  providedIn: 'root'
})
export class SecureStorageService {

  constructor() { }

  /**
   * Gets the encryption secret key.
   * If not available, it generates a new one.
   * @returns {Promise<DataType>} The encryption secret.
   */
  async getEncryptionSecret(): Promise<string> {
    return null;
    let encryptionSecret = await SecureStorage.get('llab-encryption-secret-key') as string;
    if (!encryptionSecret) {
      encryptionSecret = await this.generateEncryptionSecret();
    }
    return encryptionSecret;
  }

  /**
   * Generates a new encryption secret and stores it.
   * @returns {Promise<string>} The newly generated encryption secret.
   */
  private async generateEncryptionSecret(): Promise<string> {
    return null;
    const deviceId = (await Device.getId()).identifier;
    const timestamp = new Date().toISOString().replace(/\.|:|-/g, '');
    const encHash = `${deviceId}-${timestamp}`;
    await SecureStorage.set('llab-encryption-secret-key', encHash);
    return encHash;
  }
}
