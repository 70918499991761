<mat-form-field class="password-input mb-2" appearance="outline">
  <mat-label *ngIf="!hideLabel">
    {{'COMMON.password' | translate }}
  </mat-label>

  <input 
    class="form__input" 
    matInput 
    [formControl]="ngControl?.control" 
    [type]="passwordVisible ? 'text' : 'password'"
    autocomplete="new-password"
    data-cy="password-input">

  <mat-icon 
    class="password-input__suffix-icon" 
    matSuffix 
    (click)="changePasswordVisibility()"
    [svgIcon]="passwordVisible ? 'lms-visibility-off' : 'lms-visibility'">
  </mat-icon>
</mat-form-field>

<div 
  *ngIf="settings?.registration?.passwordStrength as passwordStrength" 
  class="password-validation"
  [class.required]="required"
  [class.has-value]="ngControl?.control.value">
  <ng-container *ngIf="passwordStrength.enabled">
    <div 
      *ngFor="let item of validationItems"
      class="password-validation__rule" 
      [class.password-validation__rule--touched]="ngControl?.control?.touched"
      [class.password-validation__rule--valid]="ngControl?.control.value && !ngControl?.control.hasError(item.errorCode)"
      data-cy="password-input-validation-rule">
      <mat-icon class="password-validation__valid-icon">check</mat-icon>
      <mat-icon class="password-validation__invalid-icon">close</mat-icon>

      <span>
        {{ item.label | translate : { count: item.data.value} }}
      </span>
    </div>
  </ng-container>
</div>