import { Component, OnInit, Input, Output, EventEmitter, QueryList, ViewChildren } from '@angular/core';
import { SlideFlipcardsDTO } from '../../../common/models';
import { FlipCardSideComponent } from '../flipcard-side/flipcard-side.component';
import { AbstractControl } from '@angular/forms';


@Component({
  selector: 'lib-flipcard',
  templateUrl: './flipcard.component.html',
  styleUrls: ['./flipcard.component.scss']
})
export class FlipCardComponent implements OnInit {
  @ViewChildren(FlipCardSideComponent) public flipcardSides: QueryList<FlipCardSideComponent>;
  
  @Input() item!: SlideFlipcardsDTO;
  @Input() slide: any;
  @Input() flipcardFormGroup: AbstractControl;
  @Input() backgroundPresigned = true;
  @Input() canEdit = false;
  @Input() isTranslation?: boolean;
  @Input() isRtl?: boolean;
  @Input() errors?: any;

  @Output() edit: EventEmitter<void> = new EventEmitter();
  @Output() changeOrientation: EventEmitter<void> = new EventEmitter();
  @Output() remove: EventEmitter<void> = new EventEmitter();
  @Output() cardFlipped: EventEmitter<void> = new EventEmitter();

  public flipped = false;

  constructor() { }

  ngOnInit() {
  }

  toggleFlip() {
    this.flipped = !this.flipped;
    this.cardFlipped.emit();

    const [front, back] = this.flipcardSides.toArray();
    const currentSide = this.flipped ? back : front;
    
    setTimeout(() => {
      currentSide?.startMedia();
    }, 100);
  }

}
