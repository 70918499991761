<div 
  class="entity-progress" 
  [class.dark]="colorScheme === colorSchemes.DARK"
  *ngIf="status ? status !== statuses.COMPLETED_ALL_ITEMS : progress !== 100; else completeTmp">
  <mat-progress-spinner
    [ngClass]="status ? 'entity-progress-spinner-status-' + status : ''"
    class="entity-progress-spinner"
    [diameter]="diameter"
    [value]="progress">
  </mat-progress-spinner>
  
  <mat-progress-spinner
    class="entity-progress-placeholder"
    [diameter]="diameter"
    [value]="100 - progress"> 
  </mat-progress-spinner>

  <span class="entity-progress-value">{{progress}}%</span>
</div>

<ng-template #completeTmp>
  <div class="entity-progress-done" [style.width.px]="diameter" [style.height.px]="diameter">
    <mat-icon>done</mat-icon>
  </div>
</ng-template>