<table 
  class="library-table"
  [class.minified-view]="minifiedView"
  mat-table
  [dataSource]="dataSource"
  matSort
  [matSortActive]="startSort?.active"
  [matSortDirection]="startSort?.direction"
  (matSortChange)="onSortChange($event)"
  data-cy="library-list-table">

  <ng-container matColumnDef="title">
    <th mat-header-cell mat-sort-header="title" class="library-table-header-cell name-cell"  *matHeaderCellDef>
      {{ 'LIBRARY_PAGE.name' | translate }}
    </th>
    <td mat-cell class="library-table-cell name-cell" *matCellDef="let row">
      <div class="library-table-name">
        <lib-library-item-icon class="library-icon" [item]="row" [size]="24"></lib-library-item-icon> 

        <div class="library-table-name-container">
          <span class="library-table-name-title">{{ row.name }}</span>

          <span class="library-table-name-modified">
            {{ 'LIBRARY_PAGE.modified' | translate }} {{ row.updated | date: 'shortDate' }}
          </span>
        </div>

        <div class="library-pinned-indicator" [class.pinned]="row.userPin" (click)="togglePinned(row, $event)" *ngIf="enablePinned">
          <lib-icon icon="push-pin" [size]="16" [fill]="row.userPin"></lib-icon>
        </div>

      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="tags">
    <th mat-header-cell class="library-table-header-cell tags-cell" *matHeaderCellDef>
      {{ 'LIBRARY_PAGE.tags' | translate }}
    </th>
    <td mat-cell *matCellDef="let row" class="library-table-cell tags-cell">
      <lib-chips [items]="row.publicTags" [count]="3"></lib-chips>
    </td>
  </ng-container>

  <ng-container matColumnDef="updated">
    <th mat-header-cell mat-sort-header="updated" class="library-table-header-cell updated-cell" *matHeaderCellDef>
      {{ 'LIBRARY_PAGE.last_modified' | translate }}
    </th>
    <td mat-cell class="library-table-cell updated-cell" *matCellDef="let row">
      {{ row.updated | date: 'shortDate' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="fileSize">
    <th mat-header-cell class="library-table-header-cell size-cell" *matHeaderCellDef>
      {{ 'LIBRARY_PAGE.file_size' | translate }}
    </th>
    <td mat-cell class="library-table-cell size-cell" *matCellDef="let row">
      <ng-container *ngIf="row.file">
        {{ row.file.fileSize | fileSize }}
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell class="library-table-header-cell actions-cell" *matHeaderCellDef></th>

    <td mat-cell class="library-table-cell actions-cell" *matCellDef="let row">
      <ng-container *ngIf="contextTemplate">
        <button mat-icon-button (click)="stopPropagation($event)" [matMenuTriggerFor]="matMenu">
          <lib-icon class="actions-cell-menu-icon" icon="dots-three-vertical" [bold]="true" [size]="16"></lib-icon>
        </button>
  
        <mat-menu class="lib-panel-menu" #matMenu="matMenu">
          <ng-container *ngTemplateOutlet="contextTemplate; context: { item: row }"></ng-container>
        </mat-menu>
      </ng-container>
    </td>
  </ng-container>

  <tr mat-header-row class="library-table-header library-table-row" *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="library-table-row" (click)="openLibraryItem(row)"></tr>
</table>