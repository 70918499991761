import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CardColorSchemeType, CustomDatePipe } from 'library-explorer';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaymentDiscount } from 'src/app/model/payment-discount';

@Component({
  selector: 'app-entity-card-status-icons',
  templateUrl: './entity-card-status-icons.component.html',
  styleUrls: ['./entity-card-status-icons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityCardStatusIconsComponent implements OnInit {
  @Input() entity: any;
  @Input() paymentEnabled: boolean;
  @Input() colorScheme: CardColorSchemeType;
  
  @Input() set discount(value: PaymentDiscount) {
    this._discount = value;
    this.setPaymentTooltip();
  }

  public get discount() {
    return this._discount;
  }

  @Output() public hovered: EventEmitter<void> = new EventEmitter();

  public priceTooltip: Observable<string>;

  public selectiveLockingItems: string;

  private _discount: PaymentDiscount;

  constructor(
    private readonly customDatePipe: CustomDatePipe,
    private readonly translateService: TranslateService) { }

  ngOnInit() {
    this.selectiveLockingItems = this.entity.selectiveLockingItems?.map(item => item.title).join(', ');
    this.setPaymentTooltip();
  }

  public paymentIconHovered(): void {
    this.hovered.emit();
  }

  public setPaymentTooltip() {
    if (!this.entity.payable) {
      return;
    }
  
    if (this.entity.isPaid) {
      const date = this.entity.paidDate && this.customDatePipe.transform(this.entity.paidDate, 'dateTimeSec');
      if (date) {
        this.priceTooltip = this.translateService.get('COMMON.paid_successfully', { date });
      } else {
        this.priceTooltip = this.translateService.get('COMMON.you_have_free_access');
      }

      return; 
    }

    const currency = this.entity.currency?.toLocaleUpperCase();

    this.priceTooltip = forkJoin([
      this.translateService.get('COMMON.paid_content', { amount: `${this.entity.price} ${currency}` }),
      this._discount && this._discount.priceWithDiscount ?
         +this._discount.priceWithDiscount
          ? this.translateService.get('COMMON.discounted_price', { amount: `${this.discount.priceWithDiscount} ${currency}`}) 
          : this.translateService.get('COMMON.you_have_free_access')
        : of('')
    ]).pipe(
      map((translations: string[]) => translations.join('. '))
    )
  }

}
