import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { WebinarDTO } from 'library-explorer';
import { WebinarsService } from '@app/services/api/webinars.service';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

@Component({
  selector: 'app-webinars-block',
  templateUrl: './webinars-block.component.html',
  styleUrls: ['./webinars-block.component.scss']
})
export class WebinarsBlockComponent implements OnInit {
  @Input() block: any;

  public placeholders = new Array(4).fill(null);

  public isLoading = true;
  public webinars$: Observable<WebinarDTO[]>;

  public carouselConfig = {
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    autoplay: false,
    centerMode: false,
    adaptiveHeight: false,
    variableWidth: true
  };

  constructor(
    private readonly webinarsService: WebinarsService
  ) { }

  ngOnInit(): void {
    const params = new HttpParams()
      .set('sort', 'dateTime:DESC')
      .set('limit', '10');

    this.webinars$ = this.webinarsService.getAllWebinars(params).pipe(
      map(res => res.items),
      finalize(() => this.isLoading = false)
    );
  }

}
