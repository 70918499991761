import { Component, Input, OnInit } from '@angular/core';
import { BundleType } from 'library-explorer';
import { LessonService } from 'src/app/services/api/lesson.service';

@Component({
  selector: 'app-entity-card-feature-icons',
  templateUrl: './entity-card-feature-icons.component.html',
  styleUrls: ['./entity-card-feature-icons.component.scss']
})
export class EntityCardFeatureIconsComponent implements OnInit {
  @Input() entity: any;

  public showNewIconIndicator: boolean;
  public newIndicatorTooltip: string;

  public lessonIndicatorBundle: BundleType;
  public achievements: string;

  public certificationsTitle: string;

  constructor(
    private readonly lessonService: LessonService
  ) { }

  ngOnInit() {
    if (this.entity.bundle === BundleType.LESSON || this.entity.bundle === BundleType.QUIZ) {
      this.lessonIndicatorBundle = this.lessonService.getLessonIndicatorBundle(this.entity);
    }

    this.achievements = this.entity.achievements?.map(item => item.title).join(', ');
    this.certificationsTitle = this.entity.certifications?.map(item => item.title).join(', ');

    this.setNewIndicatorTooltip();
  }

  private setNewIndicatorTooltip(): void {
    if (!this.entity) {
      return;
    }

    const { progress, hasNewLessons, isNew, bundle } = this.entity;

    if (progress === 100) {
      return;
    }

    if (!isNew && !hasNewLessons) {
      return;
    }

    this.showNewIconIndicator = true;

    if (hasNewLessons && !isNew) {
      this.newIndicatorTooltip = 'MODULE_LESSON_CARD.new_lesson';
      return;
    }

    this.newIndicatorTooltip = bundle === BundleType.LESSON 
      ? 'MODULE_LESSON_CARD.new_lesson' : 'MODULE_LESSON_CARD.new_module';
  }
}
