<section class="feature-highlight block-container" *ngIf="block" [style.color]="block.textColor">
  <div class="content">
    <div class="content-header" [class.no-description]="!block.text">
      <div class="wrapper">
        <div class="title" [rtl]="block.langcode">
          <h1 [innerHtml]="block.title"></h1>
          <div class="subtitle" [class.primary-color]="!block.textColor" *ngIf="block.subTitle" [innerHtml]="block.subTitle"></div>
        </div>
        <p *ngIf="block.text" class="ck-text" [innerHtml]="block.text | safeHtml" [rtl]="block.langcode"></p>
      </div>
    </div>
    <ng-container *ngIf="block.sliderEnabled; else noCarousel">
      <ngx-slick-carousel
        slickCarouselResize
        class="carousel" 
        #slickModal="slick-carousel"
        (init)="slickInit($event)"
        (afterChange)="afterChange($event)"
        (breakpoint)="breakpoint($event)"
        [config]="carouselConfig">
        <div ngxSlickItem class="item" *ngFor="let item of block.items">
          <div class="image" *ngIf="item.mediaImage">
            <img [cropImage]="item.mediaImage | preSignedUrl | async" [additionalOptions]="{width: 300, height: 300, func: 'bound'}" [attr.alt]="item.mediaImage.alt">
          </div>
          <div class="item-text ck-text" [innerHtml]="item.text | safeHtml" [rtl]="item.langcode"></div>
        </div>
      </ngx-slick-carousel>
      <div class="d-flex carousel-buttons-wrapper" *ngIf="showCarouselBtns">
        <button color="primary" mat-fab class="carousel-button left" [style.color]="block.textColor" [style.border-color]="block.textColor" [class.disabled]="isFirstSlide | async" (click)="slickModal.slickPrev()">
          <mat-icon>navigate_before</mat-icon>
        </button>
        <button color="primary" mat-fab class="carousel-button right" [style.color]="block.textColor" [style.border-color]="block.textColor" [class.disabled]="isLastSlide | async" (click)="slickModal.slickNext()">
          <mat-icon>navigate_next</mat-icon>
        </button>
      </div>
    </ng-container>
    <ng-template #noCarousel>
      <div class="item-list">
        <div class="item" *ngFor="let item of block.items">
          <div class="image" *ngIf="item.mediaImage">
            <img [cropImage]="item.mediaImage | preSignedUrl | async" [additionalOptions]="{width: 300, height: 300, func: 'bound'}" [attr.alt]="item.mediaImage.alt">
          </div>
          <div class="item-text ck-text" [innerHtml]="item.text | safeHtml" [rtl]="block.langcode"></div>
        </div>
      </div>
    </ng-template>
  </div>
</section>
