import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BundleType } from 'library-explorer';
import { NavigateFrom } from '@app/model/enums/navigate-from.enum';
import { LearningPathModel } from '@app/model/learning-path.model';
import { LearningPathService } from '@app/services/api/learning-path.service';
import { bundleTypeTextMap } from '@app/shared/constants/bundle-type-text-map.const';
import { Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { LanguageService } from '@app/services/language.service';

@Component({
  selector: 'app-learning-path-block',
  templateUrl: './learning-path-block.component.html',
  styleUrls: ['./learning-path-block.component.scss']
})
export class LearningPathBlockComponent implements OnInit, OnDestroy {

  @Input() block: any;
  
  public learningPathList: LearningPathModel[] = [];
  public activeLearningPaths = 0;
  public completedLearningPaths = 0;
  public currentSlideIndex = 0;

  public BundleType = BundleType;
  public bundleTypeTextMap = bundleTypeTextMap;
  public NavigateFrom = NavigateFrom;

  public carouselConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: 'ondemand',
    infinite: false,
    arrows: true,
    autoplay: false,
    centerMode: false,
    adaptiveHeight: true,
    dots: false,
    rtl: false
  };

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private readonly learningPathService: LearningPathService,
    private readonly languageService: LanguageService,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    const isRtl = this.languageService.isRtl();
    if (isRtl) {
      this.carouselConfig.rtl = true;
    }

    this.learningPathList = this.block.contents
      .map(this.learningPathService.processLearningPath)
      .map((item: LearningPathModel) => {
          item.nextLesson = this.learningPathService.getNextLesson(item.uuid).pipe(
            shareReplay({bufferSize: 1, refCount: true})
          );
          return item;
        }
      );
    this.activeLearningPaths = this.learningPathList.reduce((prev, item) => prev += item.progress !== 100 ? 1 : 0, 0)
    this.completedLearningPaths = this.learningPathList.reduce((prev, item) => prev += item.progress === 100 ? 1 : 0, 0)
  }

  public slideChange(e): void {
    this.currentSlideIndex = e.currentSlide;
  }

  public navigateToLearningPath(learningPath: LearningPathModel): void {
    this.router.navigate(['/learning-path', learningPath.uuid]);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
