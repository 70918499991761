import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgxDocViewerComponent } from 'ngx-doc-viewer';
import { FileOpener } from '@capacitor-community/file-opener';
import { Capacitor } from '@capacitor/core';
import { interval, Subscription } from 'rxjs';
import { delay, finalize } from 'rxjs/operators';
import { EntityTypeId } from 'library-explorer';
import { BundleType } from 'library-explorer';
import { FilePreviewType } from 'src/app/model/enums/file-preview-type.enum';
import { UserActivityType } from 'src/app/model/enums/user-activity-type.enum';
import { DownloadService } from 'src/app/services/api/download.service';
import { FileUploadApiService, ProviderType, SlideType, FileModel, FieldName } from 'library-explorer';
import { UserActivityService } from 'src/app/services/user-activity.service';
import { ViewerTypeExtensions } from './document-viewer-extensions';
import { SettingsService } from '@app/services/settings.service';


@Component({
  selector: 'app-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.scss']
})
export class DocumentPreviewComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(NgxDocViewerComponent, { static: false }) public viewer: NgxDocViewerComponent;
  @Input() public entityId: string;
  @Input() public bundle: BundleType;
  @Input() public entityTypeId: EntityTypeId;
  @Input() public fileKey: string;
  @Input() public set fileName(value: string) {
    if (value) {
      this._fileName = value;
      this.fileExtension = value.split('.').reverse()[0].toLocaleLowerCase();

      const modes = Object.values(FilePreviewType);
      this.mode = modes.find(key => ViewerTypeExtensions[key].indexOf(this.fileExtension) !== -1) as FilePreviewType;

      this.isFileSupportedByViewer = !!this.mode;
      const emitLoadedEvent = [FilePreviewType.IMAGE_VIEWER].indexOf(this.mode) !== -1;

      if (!this.isFileSupportedByViewer || emitLoadedEvent) {
        this.isLoading = false;
        this.isLoaded.emit(true);
      }

      this.showFullScreenBtn = this.mode !== FilePreviewType.VIDEO_VIEWER;
    }
  }
  public get fileName() {
    return this._fileName;
  }

  @Input() public set uri(value: string) {
    if (value) {
      this.fileSrc = this.mode === FilePreviewType.PDF_VIEWER ? encodeURIComponent(value) : value;
    }
  }

  @Input() public set document(value: FileModel) {
    this._document = value;
    if (!value) {
      this.fileSrc = null;
      return;
    }

    if (value.isOffline) {
      this.fileSrc = value.uri;
      return;
    }

    this.fileName = value.filename;

    this.fileUploadApiService.getPreSignedUrl(
      this.entityTypeId || EntityTypeId.PARAGRAPH,
      this.bundle || SlideType.SlideEmbeddedDocument as any,
      FieldName.AWS_FILE,
      value.key
    ).subscribe(preSignedUrl => {
      this.fileSrc = this.mode === FilePreviewType.PDF_VIEWER ? encodeURIComponent(preSignedUrl) : preSignedUrl;
    });
  }

  public get document() {
    return this._document;
  }

  @Input() public provider: ProviderType;
  @Input() public insideDialog = false;
  @Input() public forceFullScreen = false;
  @Input() public isSlide = false;
  @Input() public disableDownload = false;
  @Input() public searchKey: string;
  @Output() isLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  public showFullScreenBtn = true;
  public isFileSupportedByViewer = true;
  public fileExtension: string;
  public fileSrc: string;
  public reloadGoogleInterval: Subscription;
  public isLoading = true;
  public isDownloadLoading = false;

  public watermarkEnabled: boolean;

  public readonly modes: typeof FilePreviewType = FilePreviewType;
  public readonly activityTypes: typeof UserActivityType = UserActivityType;

  public mode: FilePreviewType;
  public allowDownloadFile = false;

  private _fileName: string;
  private _document: FileModel;

  constructor(
    private readonly userActivityService: UserActivityService,
    private readonly downloadService: DownloadService,
    private readonly settingsService: SettingsService,
    private readonly fileUploadApiService: FileUploadApiService
  ) { }

  public loaded(): void {
    if (this.reloadGoogleInterval) {
      if (this.viewer.iframes && this.viewer.iframes.first && this.viewer.iframes.first.nativeElement) {
        this.isLoaded.emit(true);
        this.isLoading = false;
        this.reloadGoogleInterval.unsubscribe();
      }

      return;
    }

    this.isLoading = false;
    this.isLoaded.emit(true);
  }

  ngOnInit() {
    this.settingsService.getSettings()
      .subscribe(data => {
        const documentDownloadEnabled = data.lesson.libraryDocumentDownload !== false;
        this.allowDownloadFile = !this.disableDownload && documentDownloadEnabled;
        this.watermarkEnabled = data.security?.watermark?.enableWatermarkForPdf;
      });
  }

  ngAfterViewInit(): void {
    this.reloadIFrame();
  }

  ngOnDestroy(): void {
    if (this.reloadGoogleInterval) {
      this.reloadGoogleInterval.unsubscribe();
    }
  }

  public logUserVideoActivity(type: UserActivityType): void {
    this.userActivityService.logUserVideoActivity(type, this.entityId, EntityTypeId.NODE, { key: this.fileKey });
  }

  public downloadFile(): void {
    //Opens document if user is on Native mobile device
    if (this._document?.isOffline && Capacitor.isNativePlatform()) {
      this.openFile();
    }

    if (this.isDownloadLoading) {
      return;
    }

    this.isDownloadLoading = true;
  
    this.downloadService.downloadFile(this.fileSrc, this.document ? this.document.provider : this.provider, this.fileName)
      .pipe(
        delay(200),
        finalize(() => this.isDownloadLoading = false)
      )
      .subscribe(() => {
        this.userActivityService.logUserDownloadFileActivity(this.entityId);
      });
  }

  async openFile() {
    const filePath = this._document?.filePath;
    if (!filePath) return;
    await FileOpener.open({ filePath });
  }

  public reloadIFrame(): void {
    if (this.mode === FilePreviewType.GOOGLE_VIEWER) {
      this.isLoaded.emit(false);
      this.isLoading = true;
      this.reloadGoogleInterval = interval(2000)
        .subscribe(() => {
          const iframe = (this.viewer.iframes && this.viewer.iframes.first && this.viewer.iframes.first.nativeElement) as HTMLIFrameElement;

          this.viewer.reloadIFrame(iframe);
        });
    }
  }
}
