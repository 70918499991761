import { Injectable } from '@angular/core';
import { HttpService } from 'library-explorer'
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { PushNotifications } from '@capacitor/push-notifications';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  private storeTokenEndPoint = 'https://4xlyct7ttbsc65rew6azdmoxi40rtrmy.lambda-url.eu-west-1.on.aws';
  constructor(private httpService: HttpService) { 

  }

  async addNotificationListeners() {
    
    if (!Capacitor.isNativePlatform() || (await Device.getInfo()).platform !== 'ios') return;

    await PushNotifications.addListener('registration', token => {
      if (token) this.storeDeviceToken(token.value);
    });

    await PushNotifications.addListener('registrationError', err => {
      //console.error('Registration error: ', err.error);
    });

    await PushNotifications.addListener('pushNotificationReceived', notification => {
      //console.log('Push notification received: ', notification);
    });

    await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
      //console.log('Push notification action performed', notification.actionId, notification.inputValue);
    });
  }

  async registerNotifications() {

    if (!Capacitor.isNativePlatform() || (await Device.getInfo()).platform !== 'ios') return;

    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }

    await PushNotifications.register();
  }

  async unRegisterNotifications() {
    if (!Capacitor.isNativePlatform() || (await Device.getInfo()).platform !== 'ios') return;
    await PushNotifications.unregister();
  }

  async removeNotificationAllListeners() {
    if (!Capacitor.isNativePlatform() || (await Device.getInfo()).platform !== 'ios') return;
    await PushNotifications.removeAllListeners();
  }

  async getDeliveredNotifications() {
    const notificationList = await PushNotifications.getDeliveredNotifications();
    console.log('delivered notifications', notificationList);
  }

  storeDeviceToken(token: string) {
    this.httpService.postThirdParty(this.storeTokenEndPoint, { deviceToken: token })
      .subscribe(
        (data) => console.log('Successfully subscribed for notifications!'),
        (error) => console.error('Error:', error)
      );
  }
}
