<div class="entity-card-item__child-item" [class.locked]="entity.isLocked" [class.open]="isExtended" [ngClass]="colorScheme" (click)="navigate(entity)">
  <div class="entity-card-item__child__left-wrapper">
    <ng-container *ngTemplateOutlet="imageTmp; context: { item: entity, width: 200 }"></ng-container>
    <div class="entity-card-item__child__title" ellipsis>{{entity.title}}</div>
  </div>
  <div class="entity-card-item__child__right-wrapper">
    <div *ngIf="entity.bundle === bundles.COURSE && entity.modulesCount && !entity.isLocked && !skipModuleLevel" class="entity-card-item__child__extend-arrow" [class.open]="isExtended" (click)="toggleExtend($event)">
      <mat-icon svgIcon="lms-select-arrow"></mat-icon>
    </div>
    <div class="entity-card-item__child__progress" *ngIf="!hideProgress && !childCounterVisible">
      <ng-container *ngTemplateOutlet="progressValueTmp"></ng-container>
    </div>
    <ng-container *ngIf="childCounterVisible; then counterTmp; else timeTmp"></ng-container>
  </div>
</div>
<div class="entity-card-item__grandchild-container" [ngClass]="colorScheme" *ngIf="isExtended">
  <mat-spinner *ngIf="isExtendedListObservableLoading" [diameter]="40"></mat-spinner>
  <ng-container *ngIf="extendedListObservable$ | async as childItems">
    <div class="entity-card-item__grandchild-item-list" [@fadeAnimation]="childItems?.length">
      <div class="entity-card-item__grandchild-item-wrapper" *ngFor="let grandchild of childItems">
        <div class="entity-card-item__grandchild-item"  [class.locked]="grandchild.isLocked" (click)="navigate(grandchild)">
          <ng-container *ngTemplateOutlet="imageTmp; context: { item: grandchild, width: 100 }"></ng-container>
          <div class="title" ellipsis>{{grandchild.title}}</div>
        </div>
      </div>
    </div>
    <div class="entity-card-item__grandchild-no-items" *ngIf="!childItems.length">
      {{'COMMON.no_results' | translate}}
    </div>
  </ng-container>
</div>

<ng-template #imageTmp let-item="item" let-width="width">
  <div class="entity-card-item__child__image-container">
    <div class="entity-card-item__child__image-wrapper">
      <div class="entity-card-item__child__image"
        *ngIf="(item.mediaImage || item.image) else placeholderImg" 
        [cropImage]="((item.mediaImage | preSignedUrl : item.entityTypeId : item.bundle : fieldNames.MEDIA_IMAGE) | async) || item.image" 
        [crop]="item.mediaImage?.crop"
        [imageWidth]="width"></div>
    </div>
  </div>
</ng-template>

<ng-template #placeholderImg>
  <div class="entity-card-item__child__image placeholder" [ngClass]="entity.bundle"></div>
</ng-template>

<ng-template #timeTmp>
  <div class="entity-card-item__child__actions-time" (click)="toggleChildCounter($event)">
    <mat-icon svgIcon="lms-clock-alt"></mat-icon>
    <span class="entity-card-item__child__actions-time-value" *ngIf="entity.time">{{entity.time | minutesToTime : 'short' }}</span>
  </div>
</ng-template>

<ng-template #progressValueTmp>
  <app-entity-progress-spinner 
    class="entity-progress" 
    [progress]="entity.progress" 
    [status]="entity.progressStatus" 
    [diameter]="40">
  </app-entity-progress-spinner>
</ng-template>

<ng-template #counterTmp>
  <div class="entity-card-item__child__info" (click)="toggleChildCounter($event)">
    <div class="counter" *ngIf="!hideProgress">
      <div class="entity-card-item__child__progress">
        <ng-container *ngTemplateOutlet="progressValueTmp"></ng-container>
      </div>
    </div>
    <div class="counter">
      <ng-container *ngTemplateOutlet="timeTmp"></ng-container>
    </div>
    <div class="counter" *ngFor="let counter of counterList">
      <div class="title">{{counter.name | translate}}</div>
      <div class="value">{{counter.count }}</div>
    </div>
  </div>
</ng-template>
