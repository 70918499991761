import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { EntityItemSizeEnum } from '@app/model/enums/entity-item-size.enum';
import { SettingsService } from '@app/services/settings.service';
import { BundleType, CardColorSchemeType, EntityItemLayoutEnum, FieldName } from 'library-explorer';
import { Observable, Subject } from 'rxjs';
import { finalize, shareReplay, takeUntil } from 'rxjs/operators';
import { DownloadStatus } from 'src/app/model/enums/download-status.enum';
import { PaymentDiscount } from 'src/app/model/payment-discount';
import { CourseService } from 'src/app/services/api/course.service';
import { IdbService } from 'src/app/services/idb.service';
import { PaymentService } from 'src/app/services/payment.service';
import { fadeStaggerAnimation } from '../../constants/animations.const';

@Component({
  selector: 'app-entity-card',
  templateUrl: './entity-card.component.html',
  styleUrls: ['./entity-card.component.scss'],
  animations: [fadeStaggerAnimation]
})
export class EntityCardComponent implements OnInit, OnDestroy {
  @Input() availableForDownload = false;
  @Input() insideCarousel = false;
  @Input() label: string;
  @Input() paymentEnabled = false;
  @Input() size: EntityItemSizeEnum = EntityItemSizeEnum.NORMAL;
  @Input() layout: EntityItemLayoutEnum = EntityItemLayoutEnum.GRID;
  @Input() public set item(value: any) {
    if (!value) {
      return;
    }

    this._item = value;
    this.description = value.description ? value.description.replace(/&nbsp;/g, ' ') : '';
    this.useDefaultColorScheme = value.bundle === BundleType.LESSON || value.bundle === BundleType.QUIZ;
    this.setCounterList();
  }

  public get item() {
    return this._item;
  }

  @HostBinding('attr.data-cy')
  @Input() dataAttribute: string;

  public skipModuleLevel: boolean;
  @Output() clickHandler: EventEmitter<Event> = new EventEmitter();

  @Output() deleteOfflineData: EventEmitter<boolean> = new EventEmitter();
  @Output() saveForOffline: EventEmitter<void> = new EventEmitter();
  @Output() cancelSaveForOffline: EventEmitter<void> = new EventEmitter();

  public description: string;

  public childCounterVisible = false;

  public counterList = [];

  public discount: PaymentDiscount;

  public readonly fieldNames: typeof FieldName = FieldName;

  public readonly bundles: typeof BundleType = BundleType;
  public readonly downloadStatuses: typeof DownloadStatus = DownloadStatus;
  public readonly entityItemLayouts: typeof EntityItemLayoutEnum = EntityItemLayoutEnum;
  public readonly cardColorSchemeType: typeof CardColorSchemeType = CardColorSchemeType;
  public readonly entityItemSizeEnum: typeof EntityItemSizeEnum = EntityItemSizeEnum;

  public donwloadStatus: DownloadStatus;
  public hideProgress = false;
  public isExtended = false;
  public extendedListObservable$: Observable<any>;
  public isExtendedListObservableLoading = true;
  public colorScheme: CardColorSchemeType = CardColorSchemeType.DARK;

  private readonly defaultColorScheme = CardColorSchemeType.LIGHT;
  private useDefaultColorScheme = false;
  private _item: any;
  private unsubscribe: Subject<void> = new Subject();


  constructor(
    private readonly paymentService: PaymentService,
    private readonly settings: SettingsService,
    private readonly courseService: CourseService,
    private readonly idbService: IdbService
  ) { }


  ngOnInit(): void {
    this.settings.getSettings()
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(data => {
        this.hideProgress = this.item.isLocked || data.lesson && data.lesson.hideProgressIndicators;
        this.skipModuleLevel = data.system.skip_module;
        this.colorScheme = this.useDefaultColorScheme ? this.defaultColorScheme : data.colors.cardColorScheme;
        this.setChildListObservable();
        this.setCounterList();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public loadDiscount(): void {
    if (this.discount) {
      return;
    }

    if (!this.paymentEnabled || !this.item.payable || this.item.isPaid) {
      return;
    }

    this.paymentService.getEntityDiscount(this.item?.id)
      .subscribe(data => this.discount = data);
  }

  public toggleChildCounter(event: Event): void {
    this.stopPropagation(event);
    this.childCounterVisible = !this.childCounterVisible;
  }

  public clickEvent(event: Event): void {
    if (!this.item.isLocked) {
      this.clickHandler.emit(event);
    }
  }

  public stopPropagation(event: Event) {
    event.stopPropagation();
    event.preventDefault();
  }

  public saveOffline(): void {
    this.saveForOffline.emit();
  }

  public cancelSaveOffline(): void {
    this.cancelSaveForOffline.emit();
  }

  public removeOfflineData() {
    this.deleteOfflineData.emit();
  }

  public setCounterList(): void {
    switch (this._item.bundle) {
      case BundleType.CLASS:
        this.counterList = [
          {
            name: 'COMMON.courses',
            count: this._item.coursesCount
          },
          !this.skipModuleLevel && {
            name: 'COMMON.modules',
            count: this._item.modulesCount
          },
          {
            name: 'COMMON.lessons',
            count: this._item.lessonsCount
          },
          {
            name: 'COMMON.quizzes',
            count: this._item.quizzesCount
          }
        ];
        break;
      case BundleType.COURSE:
        this.counterList = [
          !this.skipModuleLevel && {
            name: 'COMMON.modules',
            count: this._item.modulesCount
          },
          this._item.lessonsCount && {
            name: 'COMMON.lessons',
            count: this._item.lessonsCount
          },
          this._item.quizzesCount && {
            name: 'COMMON.quizzes',
            count: this._item.quizzesCount
          }
        ];
        break;
      case BundleType.MODULE:
        this.counterList = [
          this._item.lessonsCount && {
            name: 'COMMON.lessons',
            count: this._item.lessonsCount
          },
          this._item.quizzesCount && {
            name: 'COMMON.quizzes',
            count: this._item.quizzesCount
          }
        ];
        break;
      case BundleType.LESSON:
        this.counterList = [
          {
            name: 'COMMON.chapters',
            count: this._item.childAmount
          }
        ];
        break;
      case BundleType.QUIZ:
        this.counterList = [
          {
            name: 'COMMON.questions',
            count: this._item.childAmount
          }
        ];
        break;
    }

    this.counterList = this.counterList.filter(item => item);
  }

  public toggleExtend(event): void {
    if (this.item.isLocked) {
      return;
    }

    this.stopPropagation(event);
    this.isExtended = !this.isExtended;
  }

  public setChildListObservable(): void {
    this.isExtendedListObservableLoading = true;
    this.extendedListObservable$ = this.courseService.getCoursesOfClass(this.item.id);

    this.extendedListObservable$ = this.extendedListObservable$.pipe(
      finalize(() => this.isExtendedListObservableLoading = false),
      shareReplay({bufferSize: 1, refCount: true})
    );
  }
}
