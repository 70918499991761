import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSliderChange } from '@angular/material/slider';
import * as WaveSurfer from 'wavesurfer.js';
import CursorPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.cursor.min.js';
import Color from 'color';

@Component({
  selector: 'lib-audio-player-v2',
  templateUrl: './audio-player-v2.component.html',
  styleUrls: ['./audio-player-v2.component.scss']
})
export class AudioPlayerV2Component {

  @ViewChild('waveform') public container: ElementRef;

  @Input() preventTimeSeek: boolean;
  @Input() backgroundColor?: string;
  @Input() set title(value: string) {
    const oldTitle = this._title;
    this._title = value;
    this.showPlayer = !this._title;

    if (!!oldTitle !== !!value) {
      this.firstStart = true;

      setTimeout(() => {
        this.waveSurfer?.drawBuffer();
      })
    }
  }
  public get title(): string {
    return this._title;
  }
  @Input() set textColor(value: string) {
    this._textColor = value;

    if (this.waveSurfer) {
      this.setColorTheme();
    }
  }
  public get textColor(): string {
    return this._textColor;
  }
  @Input() set src(value: string) {
    if (!value) {
      return;
    }

    this._src = value;

    if (this.waveSurfer) {
      this.waveSurfer.load(value);
      return;
    }

    this.initWaveSurfer(value);
  }
  public get src(): string {
    return this._src;
  }

  @Output() finished: EventEmitter<void> = new EventEmitter();
  @Output() durationInitialized: EventEmitter<number> = new EventEmitter();

  public isPlaying = false;
  public duration = 0;
  public durationString = '0:00';
  public playbackRate = 1;
  public volume = 100;
  public isMuted = false;
  public init = false;
  public firstStart = true;
  public showPlayer = false;
  public waveSurfer;

  private _title: string;
  private _src: string;
  private _textColor: string;

  private readonly PLAYBACK_RATES = [.5, 1, 2];
  private readonly FAST_FORWARD_TIME = 10;

  private initWaveSurfer(src: string, start?: boolean) {
    requestAnimationFrame(() => {
      if (!this.container) {
        return;
      }

      this.showPlayer = true;
      this.waveSurfer = WaveSurfer.create({
        container: this.container.nativeElement,
        responsive: true,
        barWidth: 1,
        barRadius: 1,
        cursorWidth: 1,
        height: 48,
        barGap: 6,
        interact: !this.preventTimeSeek,
        plugins: [
          CursorPlugin.create({
            showTime: true,
            opacity: 1,
            customShowTimeStyle: {
              'background-color': '#000000',
              color: '#fff',
              padding: '2px',
              'font-size': '12px'

            }
          })
        ]
      });

      this.setColorTheme();

      this.waveSurfer.load(src);

      this.waveSurfer.on('finish', () => {
        this.isPlaying = false;
        this.finished.emit();
      });

      this.waveSurfer.on('ready', () => {
        this.duration = this.waveSurfer.getDuration();
        this.volume = this.waveSurfer.getVolume() * 100;
        this.durationString = this.convertTime(this.duration);
        this.isMuted = this.waveSurfer.getMute();
        this.playbackRate = this.waveSurfer.getPlaybackRate();
        this.durationInitialized.emit(this.duration);

        if (start && !this.isPlaying) {
          this.togglePlay();
        }
      });
  
      this.waveSurfer.on('redraw', () => {
        this.init = true;
      });
    });
  }

  public startAudio(): void {
    this.showPlayer = true;
    this.initWaveSurfer(this.src, true);
  }

  public togglePlay() {
    if (this.firstStart) {
      setTimeout(() => {
        this.waveSurfer.drawBuffer();
      });
    }

    this.showPlayer = true;
    this.firstStart = false;
    this.waveSurfer.playPause();
    this.isPlaying = this.waveSurfer.isPlaying();
  }

  public fastForward() {
    this.waveSurfer.skipForward(this.FAST_FORWARD_TIME)
  }

  public rewind() {
    this.waveSurfer.skipBackward(this.FAST_FORWARD_TIME);
  }

  public setVolume(change: MatSliderChange) {
    this.waveSurfer.setVolume(change.value / 100);

    if (change.value === 0) {
      this.waveSurfer.setMute(true);
      this.isMuted = true;
    } else if (this.isMuted) {
      this.waveSurfer.setMute(false);
      this.isMuted = false;
    }

    this.volume = change.value;
  }

  public mute(): void {
    this.waveSurfer.toggleMute();
    this.isMuted = this.waveSurfer.getMute();
  }

  public togglePlaybackRate(): void {
    const currentRateIndex = this.PLAYBACK_RATES.findIndex(rate => rate === this.playbackRate);
    const nextRateIndex = (currentRateIndex + 1) % this.PLAYBACK_RATES.length;
    const isPlaying = this.waveSurfer.isPlaying();

    this.playbackRate = this.PLAYBACK_RATES[nextRateIndex];
    this.waveSurfer.pause();
    this.waveSurfer.setPlaybackRate(this.playbackRate);

    if (isPlaying) {
      this.waveSurfer.play();
    }
  }

  private setColorTheme(): void {
    if (!this.textColor) {
      return;
    }

    this.waveSurfer.setWaveColor(Color(this.textColor).alpha(.4).rgb().string() || '#22222440');
    this.waveSurfer.setProgressColor(this.textColor || '#222224');
    this.waveSurfer.setCursorColor(this.textColor || '#222224');
  }

  private convertTime(value: number): string {
    if (!value || !isFinite(value)) {
      return null;
    }

    let time = '';
    const duration = Math.round(value);
    const minutes = Math.floor(duration % 3600 / 60);
    const seconds = Math.ceil(duration % 3600 % 60);

    if (minutes < 10 && seconds < 10) {
      time = '0' + minutes + ':0' + seconds;
    } else if (minutes < 10) {
      time = '0' + minutes + ':' + seconds;
    } else if (seconds < 10) {
      time = minutes + ':0' + seconds;
    } else {
      time = minutes + ':' + seconds;
    }

    return time;
  }

  ngOnDestroy(): void {
    if (!this.isPlaying) {
      return;
    }

    this.waveSurfer.stop();
  }

}
