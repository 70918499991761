import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { WatermarkService } from '@app/services/watermark.service';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';
import { v4 as uuidv4 } from 'uuid';
@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent {
  @ViewChild(PdfJsViewerComponent) public pdfJsViewerComponent: PdfJsViewerComponent;

  @Input() public allowDownloadFile = false;
  @Input() public src: string;
  @Input() public watermarkEnabled: boolean;
  @Input() public forceFullScreen: boolean;
  @Output() public loaded: EventEmitter<void> = new EventEmitter();

  public viewerId = uuidv4();

  constructor(private readonly watermarkService: WatermarkService) {
  }
  
  public pdfLoaded(): void {
    this.loaded.emit();

    this.setWatermark();
  }

  public onPageChange(): void {
    this.setWatermark();
  }

  private setWatermark(): void {
    if (!this.watermarkEnabled) {
      return;
    }

    const { PDFViewerApplication} = this.pdfJsViewerComponent;

    PDFViewerApplication.pdfViewer._pages.forEach((page, index) => {
      const pageView = PDFViewerApplication.pdfViewer.getPageView(index);
      const { height, width } = page?.viewport || {};

      this.watermarkService.appendWatermark(pageView.div, width, height, `${this.viewerId}-${index}`, 9999);
    });
  }
}
