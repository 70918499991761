import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable } from 'rxjs';
import { catchError, filter, map, mergeMap, take } from 'rxjs/operators';
import { DataResponse } from 'src/app/model/data-response.model';
import { NotificationModel } from 'src/app/model/notification.model';
import { UserCertificate } from 'src/app/model/user-certificate.model';
import { HttpService } from 'library-explorer';
import { CertificateReceivedModalComponent } from '../components/certificate-received-modal/certificate-received-modal.component';
import { environment } from 'src/environments/environment';
import { LanguageService } from 'src/app/services/language.service';
import { ProfileService } from 'library-explorer';
import { SettingsService } from '@app/services/settings.service';

@Injectable({
  providedIn: 'root'
})
export class CertificateService {
  private readonly LINKEDIN_SHARE_URL = 'https://www.linkedin.com/profile/add';

  constructor(
    private readonly toastrService: ToastrService,
    private readonly translateService: TranslateService,
    private readonly httpService: HttpService,
    private readonly modalDialog: MatDialog,
    private readonly http: HttpClient,
    private readonly profileService: ProfileService,
    private readonly languageService: LanguageService,
    private readonly settings: SettingsService
  ) {
  }


  public getCertificateImageByToken(token: string): Observable<Blob> {
    return this.http.get(`${environment.apiUrl}api/user-certificate/${token}`, { responseType: 'blob' });
  }

  public getCertificateToken(contentId: string, certificateId: string, userId: string): Observable<{ token: string }> {
    return this.httpService.post<{ token: string }>('get-certificate-token', { contentId, certificateId, userId });
  }

  public checkForUnreadCertificates(): void {
    this.getCertificationNotifications(0).subscribe(data => {
      data.forEach(item => {
        if (item.certifications) {
          item.certifications.forEach(certificate => {
            this.openCertificateNotificationDialog({
              ...item,
              customCertificate: certificate
            });
          });
  
          return;
        }
        
        this.openCertificateNotificationDialog(item);
      });
    });
  }

  public downloadCerfiticate(item: UserCertificate): Observable<void> {
    const headers = new HttpHeaders().set('Accept', 'application/pdf');
    let title = item.title;
    const lang = this.translateService.currentLang;
    const apiUrl = lang !== 'en' ? `${environment.apiUrl}${lang}/api/` : `${environment.apiUrl}api/`;
    let params = new HttpParams()
      .set('userId', this.profileService.getCurrentProfileValue()?.id)
      .set('certificateId', item.id)
      .set('apiUrl', apiUrl)
      .set('language', this.languageService.currentLang || 'en');

    if (item.customCertificate) {
      title = item.customCertificate.title;
      params = params.set('customCertificateId', item.customCertificate.id);
    }

    return this.http.get(`${environment.renderingToolUrl}/certificate`, {
      params,
      headers: this.httpService.getTokenHeader(headers),
      responseType: 'blob',
    }).pipe(
      map(cert => {
        const downloadURL = window.URL.createObjectURL(cert);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = `${title}.pdf`;
        link.click();
      })
    );
  }

  public shareCertificateOnLinkedIn(certificate: UserCertificate): void {
    const userId = this.profileService.getCurrentProfileValue()?.id;

    forkJoin([
      this.settings.getSettings(),
      this.getCertificateToken(certificate.id, certificate.customCertificate.id, userId),
    ])
      .subscribe(([data, { token }]) => {
        const url = new URL(this.LINKEDIN_SHARE_URL);
        const certificateUrl = `${environment.frontBaseDomain}/user-certificate/${token}`;
        const dateOfCompletion = certificate.dateOfCompletion ? new Date(certificate.dateOfCompletion) : new Date();

        const params = {
          startTask: 'CERTIFICATION_NAME',
          organizationId: data.certification?.linkedInSharing?.linkedInOrganizationId,
          organizationName: data.certification?.linkedInSharing?.organizationName,
          name: certificate.customCertificate.name,
          issueYear: dateOfCompletion.getFullYear(),
          issueMonth: dateOfCompletion.getMonth() + 1,
          certId: token,
          certUrl: certificateUrl
        };
    
        Object.entries(params).forEach(([key, value]) => {
          if (!value) {
            return;
          }
        
          url.searchParams.append(key, value.toString());
        });
      
    
        window.open(url, '_blank');
      });
  }

  private openCertificateNotificationDialog(item: UserCertificate): void { 
    this.modalDialog.open(CertificateReceivedModalComponent, {
      data: item,
      panelClass: 'certificate-dialog',
    });
  }

  private getCertificationNotifications(status: number = 0): Observable<UserCertificate[]> {
    const params = new HttpParams().set('status', status.toString());

    return this.httpService.get<DataResponse<NotificationModel<UserCertificate>>>('notifications/certificate', params)
      .pipe(
        map(data => data.items.map(item => item.relatedEntity))
      );
  }
}
