import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MediaApiService } from '@app/services/api/media-api.service';
import { ImageModel, ProfileUserDTO, ChatMessageType, FileModel, ProviderType, FileUploadApiService, EntityTypeId, BundleType, FieldName } from 'library-explorer';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-chat-channel-message',
  templateUrl: './chat-channel-message.component.html',
  styleUrls: ['./chat-channel-message.component.scss']
})
export class ChatChannelMessageComponent implements OnInit {
  @Input() public item: SendBird.UserMessage;
  
  @Input() public currentUserMessage = false;

  @Input() public canDelete = false;

  @Output() public mediaLoaded: EventEmitter<void> = new EventEmitter();
  @Output() public edit: EventEmitter<void> = new EventEmitter();
  @Output() public delete: EventEmitter<void> = new EventEmitter();

  public canEdit = false;

  public isFileMessage = false;

  public readonly messageTypes: typeof ChatMessageType = ChatMessageType;

  public sender: Partial<ProfileUserDTO & { fullname: string, metadata: Object }>;

  public messageMedia: Partial<FileModel>;
  public messageMediaUrl: Observable<string>;
  public messageOptions = {};

  constructor(
    private readonly mediaApiService: MediaApiService,
    private readonly fileUploadApiService: FileUploadApiService) { }

  ngOnInit(): void {
    this.parseMessageData();
    this.isFileMessage = this.item.messageType !== 'user' || !!this.item.customType;
    this.canEdit = this.currentUserMessage && !this.isFileMessage;

    this.sender = { 
      fullname: this.item.sender?.nickname || 'Admin', 
      mediaImage: this.item.sender?.profileUrl && { url: this.item.sender?.profileUrl } as ImageModel, 
      avatarUrl: this.item.sender?.profileUrl, 
      metadata: this.item.sender?.metaData 
    };
  }

  public deleteMessage(): void {
    this.delete.emit();
  }

  public editMessage(): void {
    this.edit.emit();
  }

  private parseMessageData(): void {
    const messageData = this.item.data && JSON.parse(this.item.data);

    if (!messageData) {
      return;
    }

    this.messageMedia = messageData;
    this.messageOptions = messageData.options;

    this.messageMediaUrl = this.mediaApiService.getMediaEntity(messageData.mediaId)
      .pipe(
        mergeMap(media => {
          if (!media) {
            return of(null);
          }

          this.messageMedia = media;

          if (this.item.customType === ChatMessageType.VIDEO_MESSAGE) {
            return of(media.stream || media.url || media.uri);
          }
          const fieldName = this.item.customType === ChatMessageType.IMAGE_MESSAGE ? FieldName.MEDIA_IMAGE : FieldName.MEDIA_FILE;
          
          return this.fileUploadApiService.getPreSignedUrl(
            EntityTypeId.CHAT_CHANNEL, BundleType.CHAT_CHANNEL, fieldName, media.key);
        })
      );
  }
}
