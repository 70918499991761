<div mat-dialog-content [exclude]="'.branch-select-button'" (clickOutside)="onOutsideClick()">
  <div class="branch-select-dialog">
    <div class="branch-select-dialog__title">
      {{'BRANCH.branches' | translate}}
    </div>
    <div class="branch-select-dialog__list" *ngIf="!isLoading; else loadingTmp">
      <div *ngFor="let item of branchList" class="branch-select-dialog__list-item" [class.active]="currentBranch?.branchId === item.branchId" (click)="selectBranch(item.branchId)">
        <img *ngIf="item.logo" class="branch-select-dialog__list-item-logo" [cropImage]="item.logo | settingsMediaPresignedUrl | async" [dynamicWidth]="false" imageWidth="40" alt="Logo">
        <span class="branch-select-dialog__list-item-title">{{item.name}}</span>
      </div>
    </div>
    <button
      *ngIf="isSelfEnrolment"
      mat-raised-button
      (click)="navigateToBranchList()"
      [disableRipple]="true"
      class="primary-button base-button button-rounded see-all">
      {{'BRANCH.see_all_branches' | translate}}
    </button>
  </div>
</div>

<ng-template #loadingTmp>
  <mat-spinner color="primary" [diameter]="40"></mat-spinner>
</ng-template>