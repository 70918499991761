import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';
import { GlossaryItem } from 'src/app/model/glossary-item';
import { LibraryService } from 'src/app/services/api/library.service';
import { extractHTMLContent } from 'library-explorer';

@Pipe({
  name: 'glossaryTooltip'
})
export class GlossaryTooltipPipe implements PipeTransform {
  constructor(
    private readonly libraryService: LibraryService) {
  }

  transform(value: string, element: HTMLElement, enabled: boolean): Observable<string> {
    return !enabled ? of(value) : new Observable((subscriber) => {
      this.libraryService.getGlossaries()
        .pipe(
          map((glossaries: GlossaryItem[]) => {
            glossaries.forEach(glossary => {      
              value = this.transformTextBasedOnGlossaries(value, glossary);
            });

            subscriber.next(value);
          }),
          delay(500),
          tap(() => {
            this.setTooltipsPositions(value, element);

            subscriber.next(value);
            subscriber.complete();
          })
        ).subscribe();
    });
  }


  private setTooltipsPositions(value: string, parent: HTMLElement): void {
    const element = document.createElement('p');
    element.innerHTML = extractHTMLContent(value);
    parent.appendChild(element);

    Array.from(element.getElementsByClassName('glossary-tooltip')).forEach(item => {
      const itemRect = item.getBoundingClientRect();

      const isPhone = window.matchMedia('(max-width: 768px)').matches;

      const tooltipRightPosition = isPhone
        ? itemRect.left > element.clientWidth / 2
        : itemRect.left > document.body.offsetWidth - element.clientWidth / 3;

      const className = tooltipRightPosition ? 'position-right': 'position-left';
      item.classList.add(className);
    });

    parent.removeChild(element);
  }

  private transformTextBasedOnGlossaries(text: string, glossary: GlossaryItem): string {
    const element = document.createElement('p');
    element.innerHTML = text;

    const regex = new RegExp('\\b' + glossary.title + '\\b', 'gi');
    const description = extractHTMLContent(glossary.description).replace(/<(?!\/?span\b)[^>]+>/g, '');


    this.replaceTextInElement(element, regex, description);

    return element.innerHTML;
  }

  private replaceTextInElement(element: Node, regex: RegExp, text: string): void {
    element.childNodes.forEach(child => {
      switch (child.nodeType) {

        case Node.ELEMENT_NODE:
          this.replaceTextInElement(child, regex, text);
          break;
        case Node.TEXT_NODE:
          if (regex.test(child.textContent)) {
            const container = document.createElement('span');
            container.innerHTML = child.textContent.replace(regex, 
              `<span class="glossary-tooltip">$&<span class="glossary-tooltip__text">${text}</span></span>`);
            const nodesArray = Array.from(container.childNodes);
            child.replaceWith(...nodesArray);
          }

          break;
        case Node.DOCUMENT_NODE:
          this.replaceTextInElement(child, regex, text);
      }
    });
  }

}
