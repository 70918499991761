import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DownloadStatus } from '@app/model/enums/download-status.enum';

@Component({
  selector: 'app-download-progress-button',
  templateUrl: './download-progress-button.component.html',
  styleUrls: ['./download-progress-button.component.scss']
})
export class DownloadProgressButtonComponent implements OnInit {
  @Input() public uploadProgress = 0;

  @Input() public set downloaded(value: boolean) {
    this.status = value ? DownloadStatus.DONE : DownloadStatus.DEFAULT;
  }

  @Output() public download: EventEmitter<void> = new EventEmitter();
  @Output() public cancelDownload: EventEmitter<void> = new EventEmitter();

  public largeMode = false;

  public buttonLabel: string;

  public set status(value: DownloadStatus) {
    this._status = value;
    this.setButtonLabel();
  }

  public get status() {
    return this._status;
  }

  public readonly statuses: typeof DownloadStatus = DownloadStatus;

  private _status: DownloadStatus = DownloadStatus.DEFAULT;

  constructor(private readonly element: ElementRef) { }

  ngOnInit(): void {
    this.largeMode = this.element.nativeElement.offsetWidth > 100;
  }

  public downloadHandler(event: Event): void {
    event.stopPropagation();

    if (this.status === DownloadStatus.IN_PROGRESS) {
      this.status = DownloadStatus.DEFAULT;
      this.cancelDownload.emit();
    } else if (this.status === DownloadStatus.DONE) {
      // TODO: implement - Let’s ask the user in a popup ‘Would you like to delete this content to free up space?‘ 
      
      this.cancelDownload.emit();
    } else {
      this.status = DownloadStatus.IN_PROGRESS;
      this.download.emit();
    }
    return;
  }

  private setButtonLabel(): void {
    switch (this.status) {
      case DownloadStatus.IN_PROGRESS:
        this.buttonLabel = 'COMMON.downloading';
        break;
      case DownloadStatus.DONE:
        this.buttonLabel = 'COMMON.downloaded';
        break;
      default:
        this.buttonLabel = 'COMMON.download';
    }
  }

}
