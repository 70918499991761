import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BundleType } from 'library-explorer';

@Injectable({
  providedIn: 'root'
})
export class NavigateService {

  constructor(private router: Router) { }

  public navigate(url: string): void {
    const isExternal = url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    if (isExternal) {
      window.open(url, '_blank');
    } else {
      const urlFragments = url.split('?');
      const path = urlFragments[0];
      const queryParams = {};
      let queryParamsList = [];
      if (urlFragments[1]) {
        queryParamsList = urlFragments[1].split('&');
        queryParamsList.forEach(item => {
          const paramFragments = item.split('=');
          const key = paramFragments[0];
          const value = paramFragments[1];
          queryParams[key] = value;
        });
      }
      this.router.navigate([path], { queryParams });
    }
  }

  public navigateToLogin(): void {
    this.router.navigateByUrl('/login');
  }

  public navigateToClasses(): void {
    this.router.navigateByUrl('/classes');
  }

  public navigateToLibrary(): void {
    this.router.navigateByUrl('/library');
  }

  public navigateToClass(classID: string): void {
    this.router.navigateByUrl('/courses?classID=' + classID);
  }

  public navigateToCourse(courseID: string): void {
    this.router.navigateByUrl('/modules?courseID=' + courseID);
  }

  public navigateToEntity(entityId: string, entityBundle: BundleType): void {
    switch (entityBundle) {
      case BundleType.CLASS:
        this.router.navigate(['/courses'], { queryParams: { classID: entityId }, state: { autoOpen: true } });
        break;
      case BundleType.MODULE:
        this.router.navigate(['/lessons'], {
          queryParams: { moduleID: entityId },
          state: { autoOpen: true }
        });
        break;
      case BundleType.LESSON:
      case BundleType.QUIZ:
        this.router.navigate(['/chapters'], {
          queryParams: { lessonID: entityId },
          state: { autoOpen: true }
        });
        break;
      case BundleType.COURSE:
      default:
        this.router.navigate(['/modules'], { queryParams: { courseID: entityId }, state: { autoOpen: true } });
        break;
    }
  }
}
