import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { filter, map, take } from 'rxjs/operators';

import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { NgSelectConfig } from '@ng-select/ng-select';
import { Language, LanguageStorageService, ProfileService, StorageKeys, StorageService, STORAGE_KEYS, rtlLanguageCodeList } from 'library-explorer';
import { Observable, of } from 'rxjs';
import { sessionKeys } from '../shared/constants/translation-helper.const';
import { ClassService } from './api/class.service';
import { LibraryService } from './api/library.service';
import { UserService } from './api/user.service';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private readonly localStorageKey;
  private readonly renderer: Renderer2;

  constructor(
    @Inject(STORAGE_KEYS) private readonly storageKeys: StorageKeys,
    private readonly storageService: StorageService,
    private readonly languageStorageService: LanguageStorageService,
    private readonly libraryService: LibraryService,
    private readonly userService: UserService,
    private readonly config: NgSelectConfig,
    private readonly settingsService: SettingsService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly classService: ClassService,
    private readonly translateService: TranslateService,
    private readonly rendererFactory: RendererFactory2,
    private readonly profileService: ProfileService) {
      this.renderer = rendererFactory.createRenderer(null, null);
      this.localStorageKey = this.storageKeys.PLATFORM_LANGUAGE
      this.initialize();
  }

  public get currentLang(): string {
    return this.translateService.currentLang;
  }

  public updateUserContentLangauge(contentLanguage: string): void {
    const user = this.profileService.getCurrentProfileValue();

    if (!user) {
      return;
    }

    this.profileService.updateProfile({ id: user.id, contentLanguage })
      .subscribe(() => window.location.reload());
  }

  public updateUserInterfaceLanguage(langcode: string, shouldReload = true): void {
    this.storageService.setItem(this.localStorageKey, langcode);
  
    const user = this.profileService.getCurrentProfileValue();

    const updateObs = user?.id ? this.profileService.updateProfile({ id: user.id, langcode }) : of(null);

    updateObs.subscribe(() => {
      if (shouldReload) {
        window.location.reload();
        return;
      }
  
      this.clearLanguageBasedCache();
      this.resetRoute();
    });
  }

  public initLanguage(): void {
    this.getDefaultLanguage().subscribe(currentLanguage => {
      const languages = this.languageStorageService.getLanguages();
      const defaultLanguage = this.languageStorageService.defaultLanguage;
      const availableLanguages = languages.map(lang => lang.code) || [];
      const languageSupported = availableLanguages.indexOf(currentLanguage) !== -1;
      const language = languageSupported ? currentLanguage : defaultLanguage;
      const isRtl = this.isRtl(language);

      if (isRtl) {
        this.renderer.setAttribute(document.documentElement, 'dir', 'rtl');
      }

      if (currentLanguage === this.currentLang) {
        return;
      }

      if (!languageSupported && currentLanguage !== defaultLanguage) {
        this.updateUserInterfaceLanguage(this.languageStorageService.defaultLanguage, false);
      }

      this.translateService.use(language);
      this.updateNgSelectConfig();
      this.storageService.setItem(this.localStorageKey, language);

      if (language !== defaultLanguage) {
        this.settingsService.clearCache();
      }
    });
  }

  public toggelShowTranslationKeys(): void {
    const showTranslationKeys = sessionStorage.getItem(sessionKeys.active) === 'true';
    sessionStorage.setItem(sessionKeys.active, showTranslationKeys ? 'false' : 'true');
    location.reload();
  }
  
  public clearLanguageBasedCache(): void {
    this.classService.clearCache();
    this.settingsService.clearCache();
    this.libraryService.clearCache();
    this.userService.clearCache();
  }

  public async initUserPreferedContentLanguage(): Promise<Language> {
    const user = this.profileService.getCurrentProfileValue();

    if (!user || !user.id || user.contentLanguage) {
      return;
    }

    const supportedLanguages = this.languageStorageService.getContentLanguages() || [];
    const browserLanguge = this.translateService.getBrowserLang();
    const isBrowserLanguageSupported = supportedLanguages.map(item => item.code).indexOf(browserLanguge) !== -1;

    if (!isBrowserLanguageSupported) {
      return;
    }

    await this.profileService.updateProfile({ id: user.id, contentLanguage: browserLanguge }).toPromise();
    this.clearLanguageBasedCache();

    return supportedLanguages.find(item => item.code === browserLanguge);
  }

  public resetRoute(): void {
    const defaultStrategy = this.router.routeReuseStrategy.shouldReuseRoute;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    const lastRoute = this.getLastActivatedRoute(this.activatedRoute.snapshot);
    const currentRouteWithResolver = this.getFirstActivatedRouteWithResolver(lastRoute);
    let defaultRunGuardsAndResolvers;

    if (currentRouteWithResolver) {
      defaultRunGuardsAndResolvers = currentRouteWithResolver.routeConfig.runGuardsAndResolvers;
      currentRouteWithResolver.routeConfig.runGuardsAndResolvers =  'always'; 
    }


    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParamsHandling: 'preserve',
    }).then(() => {
      this.router.routeReuseStrategy.shouldReuseRoute = defaultStrategy;
  
      if (currentRouteWithResolver) {
        currentRouteWithResolver.routeConfig.runGuardsAndResolvers = defaultRunGuardsAndResolvers;
      }
    });
  }

  public isRtl(lang = this.currentLang): boolean {
    return rtlLanguageCodeList.indexOf(lang) !== -1;
  }

  private getCurrentLanguage(): string {
    const profile = this.profileService.getCurrentProfileValue();
    
    if (profile) {
      return profile.langcode;
    }

    return this.storageService.getItem(this.localStorageKey);
  }

  private updateNgSelectConfig(): void {
    this.translateService.get('COMMON.no_results')
      .subscribe(translation => this.config.notFoundText = translation);
  }

  private initialize(): void {
    this.profileService.getCurrentProfile()
      .pipe(
        filter(value => !_.isNil(value)),
        take(1)
      )
      .subscribe(() => {
        this.initLanguage();
      });
  }

  private getLastActivatedRoute(shapshot: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    if (shapshot.firstChild) {
      return this.getLastActivatedRoute(shapshot.firstChild);
    }

    return shapshot;
  }

  private getFirstActivatedRouteWithResolver(shapshot: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    if (shapshot?.routeConfig?.resolve) {
     return shapshot; 
    }
  
    if (shapshot?.parent) {
      return this.getFirstActivatedRouteWithResolver(shapshot.parent);
    }

    return null;
  }

  private getDefaultLanguage(): Observable<string> {
    return this.settingsService.getSettings().pipe(
      map(settings => {
        const queryString = window.location.search;
        const queryParams = new URLSearchParams(queryString);
        const forcedLanguageCode = queryParams.get('lang');
  
        if (forcedLanguageCode) {
          return forcedLanguageCode;
        }
    
        return this.getCurrentLanguage() || settings.languages.defaultLanguage || this.translateService.getBrowserLang();
      })
    );
   
  }

}
