import { BundleType } from "library-explorer";

export const bundleTypeTextMap = {
  [BundleType.CLASS]: 'COMMON.class',
  [BundleType.COURSE]: 'COMMON.course',
  [BundleType.MODULE]: 'COMMON.module',
  [BundleType.LESSON]: 'COMMON.lesson',
  [BundleType.QUIZ]: 'CHAPTERS.quiz',
  [BundleType.LIBRARY]: 'COMMON.library',
  [BundleType.WEBINAR]: 'COMMON.webinar',
  [BundleType.USER]: 'COMMON.users',
}