<div class="content-highlight" *ngIf="items && items.length" [style.color]="block.textColor">
  <div class="row px-4">
    <h4 class="content-highlight__title" [rtl]="block.langcode">{{title}}</h4>

    <div class="col-12 px-0 w-100" [class.py-3]="!block.sliderEnabled">
      <ng-container *ngIf="block.sliderEnabled; else listView">
        <ngx-slick-carousel
          slickCarouselResize
          class="content-highlight__carousel" 
          [config]="carouselConfig" 
          (init)="slickInit($event)"
          (breakpoint)="breakpoint($event)"
          (afterChange)="afterChange($event)"
          #slickModal>
          <div ngxSlickItem *ngFor="let item of items" class="content-highlight__slide py-3">
            <ng-container *ngTemplateOutlet="slideTmp; context: { item: item }"></ng-container>
          </div>
        </ngx-slick-carousel>

        <div class="d-flex carousel-buttons-wrapper" *ngIf="showCarouselBtns">
          <button color="primary" mat-fab class="carousel-button left" [style.color]="block.textColor" [style.border-color]="block.textColor" [class.disabled]="isFirstSlide | async" (click)="slickModal.slickPrev()">
            <mat-icon>navigate_before</mat-icon>
          </button>
          <button color="primary" mat-fab class="carousel-button right" [style.color]="block.textColor" [style.border-color]="block.textColor" [class.disabled]="isLastSlide | async" (click)="slickModal.slickNext()">
            <mat-icon>navigate_next</mat-icon>
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #listView>
  <div class="content-highlight__list-view">
    <div class="row content-highlight__row">
      <div *ngFor="let item of items" class="content-highlight__slide col-12 col-sm-6 col-md-4 col-xl-3 mb-5 px-0 px-sm-3">
        <ng-container *ngTemplateOutlet="slideTmp; context: { item: item }"></ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template let-item="item" #slideTmp>
  <app-entity-card [insideCarousel]="true" (mousedown)="onMouseDown($event)" (clickHandler)="navigate($event, item)" [item]="item" [paymentEnabled]="isPaymentEnabled"></app-entity-card>
</ng-template>
