import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { NgSelectModule } from '@ng-select/ng-select';
import { StorageModule } from '@ngx-pwa/local-storage';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SharedLibraryModule } from '../common/shared-library.module';
import { FileSizePipe } from './pipes/file-size.pipe';
import { LibraryDetailsSidebarComponent } from './components/library-details-sidebar/library-details-sidebar.component';
import { LibraryFiltersComponent } from './components/library-filters/library-filters.component';
import { LibraryItemCardComponent } from './components/library-item-card/library-item-card.component';
import { LibraryItemIconComponent } from './components/library-item-icon/library-item-icon.component';
import { LibraryListTableComponent } from './components/library-list-table/library-list-table.component';
import { LibraryListComponent } from './components/library-list/library-list.component';
import { LibraryExplorerService } from './services/library-explorer/library-explorer.service';
import { EllipsisModule } from 'ngx-ellipsis';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AdvancedSelectModule } from '../advanced-select/advanced-select.module';

@NgModule({
  declarations: [
    LibraryListComponent,
    FileSizePipe,
    LibraryFiltersComponent,
    LibraryItemCardComponent,
    LibraryItemIconComponent,
    LibraryListTableComponent,
    LibraryDetailsSidebarComponent,
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    HttpClientModule,
    NgSelectModule,
    ReactiveFormsModule,
    DragDropModule,
    NgScrollbarModule,
    SharedLibraryModule,
    StorageModule.forRoot({ IDBNoWrap: true }),
    EllipsisModule,
    MatSlideToggleModule,
    AdvancedSelectModule
  ],
  exports: [
    LibraryListComponent,
    LibraryItemCardComponent,
    LibraryDetailsSidebarComponent,
    LibraryFiltersComponent
  ],
  providers: [
    LibraryExplorerService
  ]
})
export class LibraryExplorerModule {
}
