import { Pipe, PipeTransform } from '@angular/core';
import { VideoModel } from '../models';

@Pipe({
  name: 'videoSource'
})

export class VideoEntitySourcePipe implements PipeTransform {
  transform(video: VideoModel): string {
    if (!video) {
      return '';
    }
  
    if (video.stream) {
      return video.stream;
    }

    return video.uri || video.url;
  }
}
