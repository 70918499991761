export enum DateRangeFilterOption {
  TODAY = 'today',
  LAST_7_DAYS = 'last-7-days',
  LAST_30_DAYS = 'last-30-days',
  LAST_3_MONTHS = 'last-3-months',
  LAST_6_MONTHS = 'last-6-months',
  THIS_YEAR = 'this-year',
  LAST_YEAR = 'last-year',
  THIS_WEEK = 'this-week',
  NEXT_WEEK = 'next-week',
  THIS_MONTH = 'this-month',
  NEXT_MONTH = 'next-month'
}
