<button 
  class="download-button" 
  mat-button 
  [matTooltip]="buttonLabel |  translate"
  [matTooltipDisabled]="largeMode"
  [class.short-mode]="!largeMode"
  (click)="downloadHandler($event)" 
  [class.completed]="uploadProgress >= 100 || downloaded">
  <div class="download-button-content">
    <span class="download-button-label">
      {{ buttonLabel |  translate }}
    </span>

    <app-icon class="download-button-icon" [icon]="status === statuses.IN_PROGRESS ? 'square' : 'arrow-down'" size="14" bold="true"></app-icon>
  </div>

  <ng-container *ngIf="uploadProgress > 0">
    <div class="download-button-progress-indicator" [style.width.%]="uploadProgress"></div>
    <mat-progress-spinner class="download-button-progress-spinner" [diameter]="24" [value]="uploadProgress"></mat-progress-spinner>
  </ng-container>
</button>
