import { Injectable } from '@angular/core';
import { EntityTypeId, ProfileService, VideoModel } from 'library-explorer';
import { Observable, of } from 'rxjs';
import { EntitySpentTime } from '../model/entity-spent-time';
import { UserActivityType } from '../model/enums/user-activity-type.enum';
import { UserActivity } from '../model/user-activity';
import { UserService } from './api/user.service';

@Injectable({
  providedIn: 'root'
})
export class UserActivityService {

  constructor(
    private readonly profileService: ProfileService,
    private readonly userService: UserService) { }

  public logUserDownloadFileActivity(id: string): void {
    if (!id) {
      return;
    }

    this.logUserActivity({
      relatedEntityId: id,
      type: UserActivityType.DOWNLOADED_LIBRARY_FILE,
      relatedEntityTypeId: EntityTypeId.NODE
    });
  }

  public logUserVideoActivity(type: UserActivityType, entityId: string, entityTypeId: EntityTypeId, video: Partial<VideoModel>): void {
    this.logUserActivity({
      type,
      relatedEntityId: entityId,
      relatedEntityTypeId: entityTypeId,
      additionalKey: video && (video.key || video.uri || video.url)
    });
  }

  public logUserActivity(data: UserActivity): void {
    const currentUser = this.profileService.getCurrentProfileValue();

    if (!currentUser?.id) {
      return;
    }

    this.userService.logUserActivity(data).subscribe();
  }

  public logSpentTime(data: EntitySpentTime): Observable<void> {
    const currentUser = this.profileService.getCurrentProfileValue();

    if (!currentUser || !currentUser.id) {
      return of(null);
    }

    return this.userService.logSpentTime(data);
  }

  
}
