import { Component, forwardRef, Input, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { PasswordValidationError } from 'src/app/model/enums/password-validation-error.enum';
import { SettingsModel } from 'library-explorer';
import { SettingsService } from '@app/services/settings.service';
import { regexValidator } from 'src/app/validators/regex-validator';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss']
})
export class PasswordInputComponent implements OnInit, ControlValueAccessor {
  @Input() public hideLabel = false;
  @Input() public required = false;
  public settings: SettingsModel;
  public passwordVisible = false;

  public validationItems: { errorCode: PasswordValidationError, label: string, data: { enabled: boolean, value: number } }[] = [];

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private readonly settingsService: SettingsService) {
    this.ngControl.valueAccessor = this;
  }

  public readonly validationErrors: typeof PasswordValidationError = PasswordValidationError;

  ngOnInit(): void {
    this.settingsService.getSettings()
      .pipe(
        take(1)
      )
      .subscribe(data => {
        this.settings = data;
        this.setPasswordValidators(data);
      });
  }

  public changePasswordVisibility(): void {
    this.passwordVisible = !this.passwordVisible;
  }

  public writeValue(value: string) { }

  public onChange = (value: any) => { };
  public onTouched = () => { };

  public registerOnChange(fn: (v: any) => void): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  private setPasswordValidators(data: SettingsModel): void {
    const { passwordStrength } = data.registration ;
    const { minimumLength, minLowerCaseLetters, minUpperCaseLetters, minNumbers, minSpecialCharacters } = passwordStrength;
    const validators = [];
    this.validationItems = [];

    if (this.required) {
      validators.push(Validators.required);
    }

    if (passwordStrength.enabled) {
      if (minimumLength && minimumLength.enabled) {
        this.validationItems.push({
          errorCode: PasswordValidationError.MIN_LENGTH,
          label: 'PASSWORD.minimum_length',
          data: minimumLength
        });
        validators.push(Validators.minLength(minimumLength.value));
      }

      if (minLowerCaseLetters && minLowerCaseLetters.enabled) {
        this.validationItems.push({
          errorCode: PasswordValidationError.MIN_LOWER_CASE,
          label: 'PASSWORD.minimum_lowecase_letters',
          data: minLowerCaseLetters
        });
        const expression = `(.*?[a-z]){${minLowerCaseLetters.value},}`;
        validators.push(
          regexValidator(new RegExp(expression), { [PasswordValidationError.MIN_LOWER_CASE]: true })
        );
      }

      if (minUpperCaseLetters && minUpperCaseLetters.enabled) {
        this.validationItems.push({
          errorCode: PasswordValidationError.MIN_UPPER_CASE,
          label: 'PASSWORD.minimum_uppercase_letters',
          data: minUpperCaseLetters
        });
        const expression = `(.*?[A-Z]){${minUpperCaseLetters.value},}`;
        validators.push(
          regexValidator(new RegExp(expression), { [PasswordValidationError.MIN_UPPER_CASE]: true })
        );
      }

      if (minNumbers && minNumbers.enabled) {
        this.validationItems.push({
          errorCode: PasswordValidationError.MIN_NUMBERS,
          label: 'PASSWORD.minimum_numbers',
          data: minNumbers
        });
        const expression = `(\\D*\\d){${minNumbers.value},}`;
        validators.push(
          regexValidator(new RegExp(expression), { [PasswordValidationError.MIN_NUMBERS]: true })
        );
      }

      if (minSpecialCharacters && minSpecialCharacters.enabled) {
        this.validationItems.push({
          errorCode: PasswordValidationError.MIN_SPECIAL_CHARACTERS,
          label: 'PASSWORD.minimum_special_characters',
          data: minSpecialCharacters
        });
        const expression = `(?:[^!@#$%^&*\\-_=+"'\\/.,\`]*[!@#$%^&*\\-_=+"'\\/.,\`])`.repeat(minSpecialCharacters.value);
        validators.push(
          regexValidator(new RegExp(expression), { [PasswordValidationError.MIN_SPECIAL_CHARACTERS]: true })
        );
      }
    }

    this.ngControl.control.setValidators(validators);
    this.ngControl.control.updateValueAndValidity();
  }

}
