<div class="entity-card-feature-icons">
  <div *ngIf="lessonIndicatorBundle" class="entity-card-feature-icons__item bundle" [ngClass]="lessonIndicatorBundle" [matTooltip]="('ENTITY_TYPE.' + lessonIndicatorBundle) | translate">
  </div>
  <div 
    *ngIf="entity.achievementEnabled" 
    class="entity-card-feature-icons__item" 
    data-cy="entity-card-achievement-icon-indicator"
    [matTooltip]="'COMMON.content_complete_achievements' | translate:{count: entity.achievements?.length, content: achievements}">
    <mat-icon svgIcon="lms-badge-star"></mat-icon>
  </div>

  <div 
    *ngIf="entity.certificationEnabled" 
    class="entity-card-feature-icons__item" 
    [matTooltip]="'COMMON.content_complete_certificate' | translate:{ certificate: certificationsTitle }">
    <mat-icon svgIcon="lms-certificate-indicator"></mat-icon>
  </div>
  <div *ngIf="entity.leaderboardPoints" class="entity-card-feature-icons__item points" [matTooltip]="'COMMON.content_complete_points' | translate:{points: entity.leaderboardPoints}">
    {{entity.leaderboardPoints}}
  </div>
  <div *ngIf="entity.nonMandatory" class="entity-card-feature-icons__item optional" [matTooltip]="'COMMON.not_mandatory' | translate">
    <mat-icon svgIcon="lms-branch"></mat-icon>
  </div>
  <div *ngIf="!entity.active" class="entity-card-feature-icons__item optional" [matTooltip]="'COMMON.hidden' | translate">
    <mat-icon>visibility_off</mat-icon>
  </div>

  <ng-container *ngIf="showNewIconIndicator">
    <div 
      class="entity-card-feature-icons__item new-lesson" 
      [matTooltip]="newIndicatorTooltip | translate">
      <mat-icon svgIcon="lms-exclamation-alt"></mat-icon>
      <div class="star-8"></div>
    </div>
  </ng-container>
</div>