<div class="d-flex layout-switcher">
  <div 
    (click)="changeLayoutView(layouts.ROW)" 
    class="layout-switcher__option"
    data-cy="layout-switcher-row-option"
    [class.primary-background]="layout === layouts.ROW">
    <mat-icon svgIcon="lms-list"></mat-icon>
  </div>

  <div 
    (click)="changeLayoutView(layouts.GRID)" 
    class="layout-switcher__option"
    data-cy="layout-switcher-grid-option"
    [class.primary-background]="layout === layouts.GRID">
    <mat-icon svgIcon="lms-grid"></mat-icon>
  </div>
</div>
