import { registerLocaleData } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { Language, SettingsModel } from '../models';
import { LanguageList } from '../models/language-list';
import { MaintenanceInterceptor } from '../interceptors';

import { TranslateService } from '@ngx-translate/core';
import { HttpService } from './http.service';
import { OfflineModeService } from './offline-mode.service';
import { BaseSettingsGetService } from './base-settings.service';
import { SETTINGS_SERVICE } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class LanguageStorageService {
  public initialized = false;
  public readonly defaultLanguage = 'en';

  private languages: Language[] = [];
  private contentLanguages: Language[] = [];
  private availableLanguages!: LanguageList;

  private readonly englishLang = {
    code: 'en',
    name: 'English',
    enabled: true
  };

  constructor(
    private readonly translateService: TranslateService,
    @Inject(SETTINGS_SERVICE) private readonly settingsService: BaseSettingsGetService,
    private readonly httpService: HttpService,
    private offlineModeService: OfflineModeService) { }


  public fetchLanguages(init = true): Observable<void> {
    const params = new HttpParams()
      .set(MaintenanceInterceptor.SKIP_MAINTENANCE_CHECKING_PARAM, init);

    return forkJoin([
      this.settingsService.getSettings(),
      this.httpService.get<any>('available-languages', params, true)
    ])
      .pipe(
        tap(([settings, availableLanguages]: [SettingsModel, LanguageList]) => {
          //Store available-languages for offline use
          this.offlineModeService.storeRequest(`available-languages?${params.toString()}`, availableLanguages)
        }),
        map(([settings, availableLanguages]: [SettingsModel, LanguageList]) => {
          this.setSupportedLanguages(settings, availableLanguages);
        }),
        mergeMap(() => this.loadLocales()),
        tap(() => this.initialized = true)
      );
  }

  public getAvailableLanguages(): Language[] {
    const data = this.availableLanguages?.standard || {};
    const defaultEnglishLang = this.getLanguages().find(item => item.code === this.defaultLanguage);

    const languages = Object.keys(data)
      .map(key => ({ code: key, name: this.getLanguageLabel(key) }));

    if (defaultEnglishLang) {
      languages.push(defaultEnglishLang);
    }

    return languages;
  }

  public getAvailableSpeechLanguages(): Language[] {
    const data = this.availableLanguages?.speech || {};
    const languages = Object.keys(data)
      .map(key => ({ code: key, name: this.getLanguageLabel(key) }));

    return languages;
  }


  public getLanguages(): Language[] {
    return this.languages || [];
  }

  public getContentLanguages(): Language[] {
    return this.contentLanguages || [];
  }

  public getLanguageLabel(code: string): string {
    if (code === this.englishLang.code) {
      return this.englishLang?.name;
    }

    if (!this.availableLanguages || !this.availableLanguages.standard) {
      return '';
    }

    if (!this.availableLanguages.standard[code]) {
      return '';
    }

    const { native, english } = this.availableLanguages.standard[code];

    if (english === native) {
      return english;
    }

    return `${english} (${native})`;
  }

  private setSupportedLanguages(settings: SettingsModel, availableLanguages: LanguageList): void {
    const { contentLanguages = {}, interfaceLanguages = {} } = settings?.languages || {};

    this.availableLanguages = availableLanguages;

    const codes = Object.keys(interfaceLanguages);

    this.languages = codes.map(key => ({
      code: key,
      enabled: interfaceLanguages[key].enabled,
      name: this.getLanguageLabel(key) || interfaceLanguages[key].label
    }));

    this.languages.unshift(this.englishLang);

    this.contentLanguages = settings.languages?.contentLanguageEnabled ? Object.keys(contentLanguages).map(key => ({
      code: key,
      name: this.getLanguageLabel(key) || contentLanguages[key].label,
      enabled: contentLanguages[key].enabled
    })) : [];

    if (this.contentLanguages.length) {
      this.contentLanguages.unshift(this.englishLang);
    }

    this.translateService.addLangs(codes);
    this.translateService.setDefaultLang(this.defaultLanguage);
  }

  private loadLocales(): Observable<any> {
    const keys = ['fr', 'it', 'de', 'es', 'sv', 'hu', 'nl', 'ru', 'ar', 'zh' ,'pt', 'pl', 'tr', 'ja', 'ko', 'th', 'zh-Hant', 'bg', 'et', 'uk', 'fi', 'sq', 'hr', 'ka', 'he', 'lv', 'lt', 'ro', 'sk', 'cs', 'sr', 'el'];

    const obs = keys.map(key => this.localeInitializer(key));
    return obs.length ? forkJoin(obs) : of(null);
  }

  private localeInitializer(localeId: string): Observable<any> {
    return new Observable(subscriber => {
      import(
        /* webpackInclude: /(fr|it|de|es|sv|hu|nl|ru|ar|zh|pt|pl|tr|ja|ko|th|zh-Hant|bg|et|uk|fi|sq|hr|ka|he|lv|lt|ro|sk|cs|sr|el)\.mjs$/ */
        `/node_modules/@angular/common/locales/${localeId}.mjs`
      ).then(module => registerLocaleData(module.default)
      ).then(() => {
        subscriber.next();
        subscriber.complete();
      }).catch((err) => {
        subscriber.error(err);
      })
    });
  }
}
