<section 
  class="promotion row" 
  [cropImage]="block.mediaImage | preSignedUrl | async" 
  [additionalOptions]="{width: 1920}"
  [style.color]="block.textColor">

  <ng-container *ngIf="block.backgroundVideo">
    <lib-video 
      [src]="block.backgroundVideo | videoSource"
      class="background-video background-video--absolute"
      [hideControls]="true"
      [autoplay]="true">
    </lib-video>
  </ng-container>

  <div class="row col-lg-4 promotion__block promotion__block-left mt-4 mt-md-0">
    <div class="promotion__block__wrapper">
      <h1 class="promotion__block-left-head-text" [rtl]="block.langcode">
        {{block.title}}
      </h1>
      <div 
        *ngIf="block.mediaVideo?.uri || block.mediaVideo || block.embedVideo || block.link?.uri" 
        class="promotion__block-left-play"
        (click)="playPromotionVideo()">
        <div class="promotion__block-left-play-button">
        <mat-icon class="primary-color">play_circle_filled</mat-icon>
        </div>
        <div class="ml-3">
          <span class="play-text" [rtl]="block.langcode">{{block.link?.title}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="promotion__block promotion__block-right col-12 col-lg-8 mt-5">
    <ng-container *ngIf="classList.length > 0">
      <div class="promotion__block__header flex-column flex-md-row">
        <div class="promotion__block__header-text">
          <h1 [rtl]="block.langcode">{{'DASHBOARD_v2.hi_username' | translate : { userName: userName } }}</h1>
          <h2 [innerHtml]="block.subTitle" [rtl]="block.langcode"></h2>
        </div>
        <mat-form-field appearance="outline" class="mt-5 mt-md-0" *ngIf="classList.length > 1">
          <mat-select [disableRipple]="true" [formControl]="sliderCoursesControl">
            <mat-option *ngFor="let item of classList" [value]="item.id">
              {{item.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <app-carousel [config]="courseCarouselConfig" type="promotion-course" [items]="sliderCourses"></app-carousel>
    </ng-container>
  </div>
  <div class="scroll-for-more d-none d-md-flex flex-column align-items-center">
    <div class="d-flex align-items-center justify-content-center">
      <span>{{'DASHBOARD_v2.scroll' | translate}}</span>
    </div>
    <div class="scroll-for-more__mouse">
      <div class="scroll-for-more__wheel" [style.background-color]="block.textColor"></div>
    </div>
  </div>
</section>

<div *ngIf="showPromotionVideo" class="video-gallery-modal-wrapper">
  <div class="video-gallery__wrapper d-flex flex-column justify-content-center align-items-center" (click)="closeVideoOverlay()">
    <mat-icon class="video-gallery__close-btn">close</mat-icon>
    <div class="video-gallery__player-wrapper">
      <lib-video 
        [embedVideo]="block.embedVideo" 
        [src]="block.mediaVideo | videoSource" 
        [poster]="block.mediaImage | preSignedUrl | async" 
        (click)="stopPropagation($event)"></lib-video>
    </div>
  </div>
  <div class="video-gallery-modal"></div>
</div>