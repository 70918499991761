<div class="entity-card-status-icons" [ngClass]="colorScheme">
  <div
    *ngIf="entity.isLocked"
    class="entity-card-status-icons__item locked"
    [matTooltip]="entity.selectiveLockingItems?.length
      ? ('COMMON.selective_locked_list' | translate:{content: selectiveLockingItems})
      : ('ERRORS.content_locked' | translate)">
    <mat-icon svgIcon="lms-lock-alt"></mat-icon>
  </div>
  <div
    *ngIf="entity.payable && paymentEnabled"
    class="entity-card-status-icons__item payable"
    (mouseenter)="paymentIconHovered()"
    [class.paid]="entity.isPaid"
    [matTooltip]="priceTooltip | async"
    [matTooltipShowDelay]="300">
    <mat-icon svgIcon="lms-card"></mat-icon>
  </div>
</div>