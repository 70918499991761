<ng-container *ngIf="layout === entityItemLayouts.GRID; then layoutGridTmp; else layoutRowTmp"></ng-container>

<ng-template #layoutGridTmp>
  <div
    class="entity-card-item mat-card"
    [class.inactive]="!item.active"
    [class.locked]="item.isLocked"
    [disableOnOffline]="!item.availableOffline"
    [class.carousel-item]="insideCarousel"
    [class.primary-background-hover]="!item.isLocked && colorScheme === cardColorSchemeType.PRIMARY_HOVER"
    [class.primary-background]="colorScheme === cardColorSchemeType.PRIMARY"
    [class.light]="colorScheme === cardColorSchemeType.LIGHT"
    [ngClass]="[item.bundle, (size || entityItemSizeEnum.NORMAL)]"
    (click)="clickEvent($event)" role="button">

    <ng-container *ngTemplateOutlet="imageTmp"></ng-container>

    <div class="entity-card-item__content">
      <div class="entity-card-item__progress" *ngIf="!hideProgress && item.progress">
        <mat-icon
          class="entity-card-item__progress-background primary-background-hover-child-icon"
          svgIcon="lms-progress-bg-alt"
          [class.primary-color]="colorScheme === cardColorSchemeType.PRIMARY || colorScheme === cardColorSchemeType.PRIMARY_HOVER">
        </mat-icon>
        <ng-container *ngTemplateOutlet="progressValueTmp; context: { diameter: 45 }"></ng-container>
      </div>

      <div class="entity-card-item__content-wrapper">
        <h4 class="title" ellipsis>
          {{item.title}}
        </h4>
        <div class="entity-card-item__actions" [class.counter-visible]="childCounterVisible">
          <ng-container *ngIf="!childCounterVisible; else counterTmp">
            <ng-container *ngTemplateOutlet="timeTmp"></ng-container>

            <div class="card-actions-right">

              <ng-container *ngNativeAppConditionalVisibility="false">
                <app-download-progress-button 
                  *ngIf="availableForDownload"
                  class="download-button"
                  [uploadProgress]="item?.uploadProgress"
                  [downloaded]="item.availableOffline" 
                  (download)="saveOffline()"
                  (cancelDownload)="cancelSaveOffline()">
                </app-download-progress-button>
              </ng-container>


              <app-entity-card-status-icons
                [entity]="item"
                [paymentEnabled]="paymentEnabled"
                [discount]="discount"
                [colorScheme]="colorScheme"
                (hovered)="loadDiscount()">
              </app-entity-card-status-icons>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #layoutRowTmp>
  <div
    class="entity-card-item mat-card layout-row"
    [class.inactive]="!item.active"
    [class.locked]="item.isLocked"
    [disableOnOffline]="!item.availableOffline"
    [class.carousel-item]="insideCarousel"
    [class.primary-background-hover]="!item.isLocked && colorScheme === cardColorSchemeType.PRIMARY_HOVER"
    [class.primary-background]="colorScheme === cardColorSchemeType.PRIMARY"
    [class.light]="colorScheme === cardColorSchemeType.LIGHT"
    [ngClass]="item.bundle"
    (click)="clickEvent($event)" role="button">

    <ng-container *ngTemplateOutlet="imageTmp; context: { showStatus: true }"></ng-container>

    <div class="entity-card-item__content">
      <div class="entity-card-item__content-wrapper">
        <ng-container *ngTemplateOutlet="layoutRowContentTmp; context: { $implicit: item }"></ng-container>
      </div>
    </div>
  </div>
  <div class="entity-card-item__children-container layout-row" [ngClass]="colorScheme" *ngIf="item.bundle === bundles.CLASS">
    <ng-container *ngIf="isExtended">
      <div class="loading-wrapper" *ngIf="isExtendedListObservableLoading">
        <mat-spinner [diameter]="50"></mat-spinner>
      </div>
      <ng-container *ngIf="extendedListObservable$ | async as childItems">
        <div [@fadeAnimation]="childItems?.length">
          <app-entity-card-row-child
            *ngFor="let course of childItems"
            [entity]="course"
            [hideProgress]="hideProgress"
            [skipModuleLevel]="skipModuleLevel"
            [paymentEnabled]="paymentEnabled"
            [colorScheme]="colorScheme"
            (refresh)="setChildListObservable()"
          ></app-entity-card-row-child>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #progressValueTmp let-diameter="diameter">
  <app-entity-progress-spinner
    class="entity-progress" 
    [progress]="item.progress" 
    [colorScheme]="colorScheme"
    [status]="item.progressStatus" 
    [diameter]="diameter">
  </app-entity-progress-spinner>
</ng-template>

<ng-template #counterTmp>
  <div class="entity-card-item__info" (click)="toggleChildCounter($event)">
    <div class="counter" *ngFor="let item of counterList">
      <div class="title">{{item.name | translate}}</div>
      <div class="value">{{item.count || 0 }}</div>
    </div>
  </div>
</ng-template>

<ng-template #counterExtendedTmp>
  <div class="entity-card-item__info" (click)="toggleChildCounter($event)">
    <div class="counter-wrapper">
      <div class="counter-row">
        <div class="counter">
          <div class="entity-card-item__progress">
            <ng-container *ngTemplateOutlet="progressValueTmp; context: { diameter: 32 }"></ng-container>
          </div>
        </div>
        <div class="counter" *ngFor="let item of counterList | slice:0:2">
          <div class="title">{{item.name | translate}}</div>
          <div class="value">{{item.count}}</div>
        </div>
      </div>
      <div class="counter-row">
        <div class="counter">
          <ng-container *ngTemplateOutlet="timeTmp"></ng-container>
        </div>
        <div class="counter" *ngFor="let item of counterList | slice:1:3">
          <div class="title">{{item.name | translate}}</div>
          <div class="value">{{item.count}}</div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #imageTmp let-showStatus="showStatus">

  <div class="entity-card-item__image-container">
    <div class="entity-card-item__image-wrapper">
      <app-entity-card-feature-icons [entity]="item"></app-entity-card-feature-icons>
      <div class="entity-card-item__image"
        *ngIf="(item.mediaImage || item.image) else placeholderImg"
        [cropImage]="((item.mediaImage | preSignedUrl : item.entityTypeId : item.bundle : fieldNames.MEDIA_IMAGE) | async) || item.image"
        [crop]="item.mediaImage?.crop"
        imageWidth="400"></div>
      <app-entity-card-status-icons
        *ngIf="showStatus"
        [entity]="item"
        [paymentEnabled]="paymentEnabled"
        [discount]="discount"
        (hovered)="loadDiscount()">
      </app-entity-card-status-icons>
    </div>
  </div>
</ng-template>

<ng-template #timeTmp>
  <div class="entity-card-item__actions-time" (click)="toggleChildCounter($event)">
    <mat-icon svgIcon="lms-clock-alt"></mat-icon>
    <span class="entity-card-item__actions-time-value" *ngIf="item.time">{{item.time | minutesToTime : 'short' }}</span>
  </div>
</ng-template>

<ng-template #layoutRowContentTmp let-entity>
  <div class="title" ellipsis>{{entity.title}}</div>
  <div class="right-wrapper">
    <div class="entity-card-item__extend-arrow" *ngIf="!item.isLocked && entity.bundle === bundles.CLASS" [class.open]="isExtended" (click)="toggleExtend($event)">
      <mat-icon svgIcon="lms-select-arrow"></mat-icon>
    </div>
    <div class="entity-card-item__progress" *ngIf="!hideProgress && !childCounterVisible">
      <ng-container *ngTemplateOutlet="progressValueTmp; context: { diameter: 45 }"></ng-container>
    </div>
    <ng-container *ngIf="childCounterVisible; then counterExtendedTmp; else timeTmp"></ng-container>
  </div>
</ng-template>

<ng-template #placeholderImg>
  <div class="entity-card-item__image placeholder" [entityPlaceholder]="item.bundle"></div>
</ng-template>
