import { Directive, Input } from '@angular/core';
import { SlideType, QuestionType, SlideBackgroundData, BackgroundSizeOption } from '../models';
import { MediaBackgroundDirective } from './media-background.directive';


@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[slideBackground]'
})
export class SlideBackgroundDirective extends MediaBackgroundDirective {
  @Input() public set slideBackground(value: SlideBackgroundData) {
    this._slideBackground = value;
    this.setSlideBackground();
  }

  public get slideBackground() {
    return this._slideBackground;
  }

  private readonly blackBg =  '#262738';

  private _slideBackground!: SlideBackgroundData;

  protected setSlideBackground(): void {
    if (!this._slideBackground) {
      this.el.nativeElement.style.backgroundColor = null;
      this.el.nativeElement.style.backgroundImage = null;
      this.cropImage = '';
      return;
    }

    if (this._slideBackground.bundle === SlideType.SlideWebinar || this._slideBackground.type === QuestionType.QUESTION_ASSIGNMENT) {
      this.el.nativeElement.style.backgroundColor = this.blackBg;
      this.cropImage = this._slideBackground.webinar?.mediaImage?.url || '';
      this.el.nativeElement.style.backgroundSize = BackgroundSizeOption.COVER;
    
      return;
    }

    if (!this._slideBackground.enableBackground) {
      this.el.nativeElement.style.backgroundColor = null;
      this.el.nativeElement.style.backgroundImage = null;
      this.cropImage = '';
      return;
    }

    this.mediaBackground = this._slideBackground;
  }
}
