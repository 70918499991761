export const storageKeys = {
  CLASS_LAYOUT: 'llab-class-layout',
  LIBRARY_LIST_LAYOUT: 'llab-library-list-layout',
  PLATFORM_LANGUAGE: 'llab-language',
  COOKIE_CONSENT: 'llab-cookie-consent',
  SCORM_SUSPEND_DATA: 'llab-scorm-suspend-data',
  INTERNAL_SSO_ENABLED: 'llab-internal-sso-enabled',
  SHOW_STARTED_ITEMS: 'llab-show-started',
  BRANCH_NAME: 'llab-branch-name',
  LOGGED_IN_USER: 'llab-logged-id-user',
  REFRESH_TOKEN: 'llab-refresh-token',
  OPENED_LESSON_GROUPS: 'llab-opened-lesson-groups',
  USER_SPENT_TIME: 'llab-user-spent-time',
  OFFLINE_USER_DATA: 'llab-offline-user',
  OFFLINE_COURSES: 'llab-offline-courses',
  OFFLINE_CLASSES: 'llab-offline-classes',
  PENDING_REQUESTS: 'llab-pending-requests',
};
