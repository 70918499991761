import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { BundleType, EntityTypeId, FieldName, FileChunk, PreSignRequestData, SlideType, UploadFileInfo } from '../models';
import { HttpService } from './http.service';


@Injectable({
  providedIn: 'root'
})
export class FileUploadApiService {

  constructor(
    private httpClient: HttpClient,
    private httpService: HttpService,
    private storage: StorageMap
  ) { }

  public getFileByUUID(uuid: string): Observable<string> {
    const params = new HttpParams().set('uuid', uuid);
    return this.httpService.get<any>(`aws/get-file-by-uuid`, params)
      .pipe(
        mergeMap(data => {
          if (data.isNeedPresign) {
            return this.getPreSignedUrl(data.entityTypeId, data.bundle, data.fieldName, data.key);
          }

          return of(data.url);
        })
      );
  }

  public uploadToS3(url: string, file: File | Blob): Observable<any> {
    return this.httpClient.put(url, file);
  }

  public getPreSignedUrl(
    entityTypeId: EntityTypeId,
    bundle: BundleType | SlideType,
    fieldName: FieldName,
    key: string,
    preSignedToken?: string
  ): Observable<string> {
    const data: any = {
      entityTypeId,
      bundle,
      fieldName,
      key
    };

    if (preSignedToken) {
      data.preSignedToken = preSignedToken;
    }

    const fetch$ = this.httpService.post<{ url: string }>(`get-pre-signed-url`, data);
    return this.presignedUrlFromCache(data, fetch$);
  }

  public getPreSignedUrlMulti(items: PreSignRequestData[]): Observable<string[]> {
    return this.httpService.post<{ url: string }[]>(`multipart-pre-signed-url`, { items })
      .pipe(
        map(items => {
          return items.map(item => item.url)
        })
      );
  }

  public getPreSignedUrlCookies(
    entityTypeId: EntityTypeId,
    bundle: BundleType | SlideType,
    fieldName: FieldName,
    key: string,
    preSignedToken?: string
  ): Observable<string> {
    const data: any = {
      entityTypeId,
      bundle,
      fieldName,
      key
    };

    if (preSignedToken) {
      data.preSignedToken = preSignedToken;
    }

    return this.httpService.post<{ url: string }>('get-pre-signed-cookies', data, true)
      .pipe(
        map(res => res?.url)
      );
  }

  public multipartUploadGetPart(
    chunk: FileChunk, info: UploadFileInfo, uploadId: string, key: string): Observable<{ url: string }> {
    const data = {
      type: info.fileType,
      entityTypeId: info.entityTypeId,
      bundle: info.bundle,
      fieldName: info.fieldName,
      uploadId,
      key,
      partNumber: chunk.number
    };

    return this.httpService.post<{ url: string }>(`multipart-upload-get-part`, data);
  }

  public multipartUploadStart(info: UploadFileInfo): Observable<any> {
    const data = {
      type: info.fileType,
      entityTypeId: info.entityTypeId,
      bundle: info.bundle,
      fieldName: info.fieldName,
      totalSize: info.file?.size,
      fileName: info.file?.name
    };

    return this.httpService.post(`multipart-upload-start`, data);
  }

  public multipartUploadEnd(info: UploadFileInfo, uploadId: string, key: string): Observable<any> {
    const data = {
      type: info.fileType,
      entityTypeId: info.entityTypeId,
      bundle: info.bundle,
      fieldName: info.fieldName,
      uploadId,
      key
    };

    return this.httpService.post(`multipart-upload-end`, data);
  }

  public getPreSignedPost(info: UploadFileInfo): Observable<any> {
    const data = {
      type: info.fileType,
      entityTypeId: info.entityTypeId,
      bundle: info.bundle,
      fieldName: info.fieldName,
      fileName: info.file?.name
    };

    return this.httpService.post(`get-pre-signed-post`, data);
  }

  public addDefaultTagsToS3File(info: UploadFileInfo, key: string): Observable<any> {
    const data = {
      type: info.fileType,
      entityTypeId: info.entityTypeId,
      bundle: info.bundle,
      fieldName: info.fieldName,
      key
    };

    return this.httpService.post(`add-default-tags`, data);
  }

  public awsFinishUpload(token: string): Observable<any> {
    const data = {
      token
    };

    return this.httpService.post(`aws/finish-upload`, data);
  }

  public awsUploadSendSNS(info: UploadFileInfo, key: string): Observable<any> {
    const data = {
      type: info.fileType,
      entityTypeId: info.entityTypeId,
      bundle: info.bundle,
      fieldName: info.fieldName,
      key
    };

    return this.httpService.post(`aws/send_sns`, data);
  }

  private presignedUrlFromCache(data: any, fetch$: Observable<{url: string}>): Observable<string> {  
    const getQueryParamFromUrl = (url: string, queryParam: string) =>
      url?.split('&')?.find(item => item.indexOf(`${queryParam}=`) !== -1)?.split('=')?.pop();

    const isUrlActive = (cachedDate: { url: string }): boolean => {
      if (!cachedDate) {
        return false;
      }

      const { url } = cachedDate;
      const requestDate = getQueryParamFromUrl(url, 'X-Amz-Date');
      const expirationTime = +(getQueryParamFromUrl(url, 'X-Amz-Expires') || 0) * 1000;
      const requestDateTime = moment(requestDate).valueOf();
      const now = new Date().getTime();
      const epxirationBuffer = 10 * 1000;

      return requestDateTime + expirationTime > (now + epxirationBuffer);
    };

    fetch$ = fetch$.pipe(
      tap((res) => this.storage.set(data.key, res).subscribe())
    );

    return this.storage.get(data.key, { type: 'object', properties: { url: { type: 'string' } }}).pipe(
      mergeMap((storageRes: any) => isUrlActive(storageRes) ? of(storageRes) : fetch$),
      map((res: { url: string }) => res.url)
    );
  }
}
