<ng-container *ngIf="isInitialized">
  <div class="navigation-sidebar" [class.opened]="opened">
    <div
      class="navigation-sidebar-menu" 
      [class.hidden]="!opened" 
      [class.dark-theme]="darkTheme" 
      [exclude]="'.header-menu-icon'"
      (clickOutside)="closeMenuIfMobile()">
      <nav class="sidebar-navigation">
        <ng-container *ngIf="isLoggedIn; else unauthorizedMenuItems">
          <ul class="sidebar-menu-list">
            <li class="sidebar-menu-item space-bottom-link" [disableOnOffline]="true">
              <a 
                routerLink="dashboard" 
                routerLinkActive="active-link" 
                class="sidebar-menu-link" 
                (click)="closeMenuIfMobile()"
                [matTooltip]="'COMMON.home' | translate"
                matTooltipPosition="right"
                matTooltipClass="dark-tooltip"
                [matTooltipDisabled]="opened">
                <lib-icon class="link-icon" icon="house"></lib-icon>
                <span class="menu-link-label">{{ 'COMMON.home' | translate }}</span>
              </a>
            </li>
            
            <li 
              class="sidebar-menu-item" 
              *ngFor="let item of menuItems; let last = last" 
              [class.space-bottom-link]="last" 
              [disableOnOffline]="!item.availableOffline">
              <a 
                [extendedRouterLink]="item.path" 
                [pageId]="item.pageId"
                extendedRouterLinkActive="active-link" 
                class="sidebar-menu-link" 
                [ngClass]="item.cssClass || ''"
                (click)="closeMenuIfMobile()"
                [matTooltip]="item.title | translate"
                matTooltipPosition="right"
                matTooltipClass="dark-tooltip"
                [matTooltipDisabled]="opened">
                <app-svg-icon *ngIf="item.iconUrl" class="link-icon svg-icon" [url]="item.iconUrl"></app-svg-icon>
                <lib-icon *ngIf="item.icon" class="link-icon" [icon]="item.icon"></lib-icon>
                <span class="menu-link-label">{{ item.title | translate }}</span>

                <ng-container *ngIf="item.availableOffline">
                  <label class="offline-mode-indicator" *ngIfOffline="false">{{'COMMON.offline' | translate }}</label>
                </ng-container>
              </a>
            </li>

            <div class="mobile-only navigation-menu-divider"></div>
  
            <li class="mobile-only sidebar-menu-item" [disableOnOffline]="true">
              <a 
                *ngIf="showSearchLink"
                routerLink="search" 
                routerLinkActive="active-link" 
                class="sidebar-menu-link" 
                (click)="closeMenuIfMobile()"
                [matTooltip]="'MENU.search' | translate"
                matTooltipPosition="right"
                matTooltipClass="dark-tooltip"
                [matTooltipDisabled]="opened">
                <lib-icon class="link-icon" icon="magnifying-glass"></lib-icon>
                <span class="menu-link-label">{{ 'MENU.search' | translate }}</span>
              </a>
            </li>
  
            <li class="mobile-only sidebar-menu-item" *ngIf="chatEnabled" [disableOnOffline]="true">
              <a 
                routerLink="chat" 
                routerLinkActive="active-link" 
                (click)="closeMenuIfMobile()" 
                class="sidebar-menu-link"
                [tooltip]="'COMMON.right' | translate"
                tooltipPosition="above">  
                <lib-icon class="link-icon" icon="chat-dots"></lib-icon>
                <span class="menu-link-label">{{ 'COMMON.chat' | translate }}</span>
              </a>
            </li>
  
            <li class="mobile-only sidebar-menu-item" [disableOnOffline]="true">
              <a class="sidebar-menu-link" (click)="openLanguageDialog($event)">
                <lib-icon class="link-icon" icon="globe"></lib-icon>
                <span class="menu-link-label">{{ 'COMMON.languages' | translate }}</span>
              </a>
            </li>
  
          </ul>

          <div class="mobile-only navigation-menu-divider"></div>
        
          <ul class="sidebar-menu-list" [disableOnOffline]="true">
            <li class="sidebar-menu-item" *ngIf="supportUrl">
              <a 
                [href]="supportUrl" 
                target="_blank" 
                class="sidebar-menu-link" 
                (click)="closeMenuIfMobile()"
                [matTooltip]="'COMMON.help' | translate"
                matTooltipPosition="right"
                matTooltipClass="dark-tooltip"
                [matTooltipDisabled]="opened">
                <lib-icon class="link-icon" icon="lifebuoy"></lib-icon>
                <span class="menu-link-label">{{ 'COMMON.help' | translate }}</span>
              </a>
            </li>
        
            <li class="sidebar-menu-item">
              <a 
                routerLink="/profile/legal-center" 
                routerLinkActive="active-link" 
                class="sidebar-menu-link" 
                (click)="closeMenuIfMobile()"
                [matTooltip]="'COMMON.legal_center' | translate"
                matTooltipPosition="right"
                matTooltipClass="dark-tooltip"
                [matTooltipDisabled]="opened">
                <lib-icon class="link-icon" icon="scales"></lib-icon>
                <span class="menu-link-label">{{'COMMON.legal_center' | translate}}</span>
              </a>
            </li>
          </ul>
        </ng-container>

        <ng-template #unauthorizedMenuItems>
          <ul class="sidebar-menu-list">
            <li class="sidebar-menu-item">
              <a 
                [routerLink]="['/access', 'login']" 
                routerLinkActive="active-link" 
                class="sidebar-menu-link" 
                (click)="closeMenuIfMobile()"
                [matTooltip]="'COMMON.sign_in' | translate"
                matTooltipPosition="right"
                matTooltipClass="dark-tooltip"
                [matTooltipDisabled]="opened">
                <lib-icon class="link-icon" icon="sign-in"></lib-icon>
                <span class="menu-link-label">{{ 'COMMON.sign_in' | translate }}</span>
              </a>
            </li>

            <li *ngIf="registrationEnabled" class="sidebar-menu-item">
              <a 
                [routerLink]="['/access', 'register']" 
                routerLinkActive="active-link" 
                class="sidebar-menu-link" 
                (click)="closeMenuIfMobile()"
                [matTooltip]="'COMMON.create_account' | translate"
                matTooltipPosition="right"
                matTooltipClass="dark-tooltip"
                [matTooltipDisabled]="opened">
                <lib-icon class="link-icon" icon="plus-circle"></lib-icon>
                <span class="menu-link-label">{{ 'COMMON.create_account' | translate }}</span>
              </a>
            </li>

          </ul>
        </ng-template>
      </nav>   
    </div>
  </div>

  <div class="navigation-sidebar-overlay" *ngIf="opened"></div>
</ng-container>
