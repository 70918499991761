import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CourseDetailsDialogComponent } from '@app/shared/components/course-details-dialog/course-details-dialog.component';
import { BundleType, SettingsCourseDetailsScreenOptions } from 'library-explorer';
import { BundleToPath } from '../model/enums/bundle-path.enum';
import { PaymentResultEnum } from '../model/enums/payment-result.enum';
import { PayableNode } from '../model/payable-node';
import { PaymentService } from './payment.service';
import { SettingsService } from './settings.service';
import { AuthService } from './auth.service';
import { map, take } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PayableNodeNavigationService {
  private paymentEnabled = false;

  constructor(
    private readonly authService: AuthService,
    private readonly dialog: MatDialog,
    private readonly settingsService: SettingsService,
    private readonly router: Router,
    private readonly paymentService: PaymentService) {
    this.initialize();
  }

  public async navigate(
    item: Partial<PayableNode & { parentCourseId: string, progress?: number }>,
    event: MouseEvent = null,
    prevMousePosition: { x: number, y: number } = null,
    skipCourseDetailedDialog = false): Promise<void> {

    if (prevMousePosition) {
      const { x, y } = prevMousePosition;

      if (Math.abs(x - event.screenX) > 10 || Math.abs(y - event.screenY) > 10) {
        return;
      }
    }

    if (item.bundle === BundleType.COURSE && !skipCourseDetailedDialog) {
      const isDetailOpened = await this.openCourseDetails(item).toPromise();

      if (isDetailOpened) {
        return;
      }
    }

    if (this.paymentEnabled && item.payable && !item.isPaid) {
      this.paymentService.pay(item.uuid)
        .subscribe(paymentStatus => {
          if (paymentStatus === PaymentResultEnum.ALREADY_PAID) {
            this.navigateToEntity(item);
          }
        });

      return;
    }

    this.navigateToEntity(item);
  }

  private openCourseDetails(item: Partial<PayableNode & { parentCourseId: string, progress?: number }>): Observable<boolean> {
    return combineLatest([
      this.settingsService.getSettings(),
      this.authService.isAuthorized()
    ])
    .pipe(
      take(1),
      map(([settings, isAuth]) => {
        const { courseDetailsScreen } = settings.course;
        
        if (!isAuth && courseDetailsScreen === SettingsCourseDetailsScreenOptions.ENABLED_FOR_ALL_USERS) {
          this.router.navigate(['/course-details', item.id]);
          return true;
        }
    
        if (isAuth && courseDetailsScreen !== SettingsCourseDetailsScreenOptions.DISABLED && !item.progress) {
          this.dialog.open(CourseDetailsDialogComponent, {
            data: item,
            panelClass: ['mobile-full-width-dialog', 'dialog-no-padding', 'course-details-dialog'],
          });
    
          return true;
        }
    
        return false;
      })
    );
  }

  private navigateToEntity(item: Partial<PayableNode & { parentCourseId: string }>): void {
    const bundle = item.bundle || BundleType.COURSE;
    const path = `/${BundleToPath[bundle]}`;
    const queryParams: any = {};
    const paramName = `${bundle === BundleType.QUIZ ? BundleType.LESSON : bundle}ID`;
    queryParams[paramName] = item.uuid;

    if (item.parentCourseId) {
      queryParams.courseID = item.parentCourseId;
    }

    this.router.navigate([path], { queryParams, state: { autoOpen: true } });
  }

  private initialize(): void {
    this.paymentService.getPaymentConfig().subscribe(res => {
      this.paymentEnabled = !!res.enabled;
    });
  }
}
